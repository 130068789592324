// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------
const ps = {
  demo: {
    title: `Pashto`,
    introduction: `معرفی`,
  },
  GoogleAuth: {
    RecoveryCode: 'کد بازیابی',
    loginGoogleAuth: 'با Google Authenticator Code وارد شوید',
    loginRecovery: 'با ریکاوری کد وارد شوید',
    scan: 'این کد QR را با Google Authenticator اسکن کنید',
    enterSixDigit: 'کد 6 رقمی را وارد کنید',
    verify: 'تأیید کنید',
    RecoveryCodes: 'کدهای بازیابی',
    NoCode: 'هیچ کد بازیابی موجود نیست.',
    SharedKey: 'کلید مشترک Google Auth',
    Enable2fa: '2FA را فعال کنید',
    EnableTwoFactor: 'احراز هویت دو مرحله ای را فعال کنید',
    note: 'برای ایجاد کد QR Google Authenticator خود، روی دکمه زیر کلیک کنید.',
    Disable2fa: '2fa را غیر فعال کنید',
  },
  Enable2fa: `احراز هویت 2-عاملی`,
  CRUD: {
    CreateTrade: `ایجاد ترید`,
    WithdraFromAccount: `برداشت از حساب`,
    DepositToAccount: `واریز به حساب`,
    DepositToUser: `انتقال پول`,
    CreateLoan: `ایجاد قرضه`,
    TakeOrGiveLoan: `گرفت / پرداخت قرض`,
    PayTakenLoan: `پرداخت قرض`,
    TradeEntry: `ثبت ترید`,
    DepositCash: `واریز پول`,
    WithDrawCash: `برداشت پول`,
    TakePaidLoan: `گرفت قرضه`,
    Create: `ایجاد نمودن`,
    Delete: `حذف نمودن`,
    List: `لیست`,
    Update: ` تغییر دادن`,
    back: ' برګشت ',
    DeleteTitle: `آیا میخواهید ریکارد را حذف نمایید؟`,
    Remarks: ` جزییات`,
    QuickForward: 'ارسال عاجل',
    UnForward: 'اجراآت',
    Forward: 'ارسال',
    Reject: 'رد کردن',
    DocumentLink: 'ارتباط با سند',
    Detail: `جزئیات`,
    Cancle: 'برګشت',
    Remove: 'حذف نمودن',
    Save: 'ذخیره کردن',
    Send: 'قید صادره و ارسال',
    Sadera: 'قید صادره',
    Receive: 'وارده گرفتن',
    Reset: 'تنظیم مجدد',

    BackToList: 'برګشت به لیست',
    AddAttchment: 'ضمیمه جدید اضافه نمائید',
    Edit: 'تغیر دادن',
    PasswordReset: 'تنظیم مجدد رمزعبور',
    AddNew: 'جدید اضافه کنید',
    CCDep: 'کاپی به ریاست ها',
    AddNewCC: 'ریاست جدید را در CC اضافه نمائید',
    Add: 'اضافه نمائید',
    SelectOption: 'گزینه را انتخاب نمائید',
    Yes: 'بلی',
    No: 'نخیر',
    BackToEmployee: 'برگشت به لیست',

    BackToDashboard: 'برگشت به دشبورد',
    //
    SearchButton: 'جستجو',
    SearchPlaceholder: 'جستجو...',
    DeleteAttachment: 'آیا میخواهید این ضمیمه را حذف کنید؟',
    View: 'نمایش سند',
    TaskMembers: 'اعضای اجرای عمل',
    MarkAsRead: 'خوانده شده',
    MarkAsUnread: 'خوانده نشده',
    Close: 'بسته کردن',
  },
  MainAsset: {
    AddTitle: `اضافه نمودن حساب`,
    UpdateTitle: `تغییر نمودن حساب`,
    MainAssetList: `لیست حساب ها`,
    New: `حساب جدید`,
    Update: `تمدید حساب`,
    AssetType: `نوعیت حساب`,
    Owneruser: `مالک حساب`,
    DepositDate: `تاریخ واریز`,
    BalanceAmount: `بلانس حساب`,
    Description: `تفصیلات`,
    MainAsset: `صورت حساب`,
  },
  AssetType: {
    AssetTypeList: 'لیست نوعیت حساب',
    Placeholder: 'جستجو نوعیت حساب',
    CreateAssetType: 'ایجاد نوعیت حساب',
    AssetType: `تمدید نوعیت حساب`,
    New: 'نوعیت حساب جدید',
    Update: 'تمدید نوعیت حساب',
    Title: 'نوعیت حساب',
    AddTitle: 'ثبت نوعیت حساب',
    UpdateTitle: 'تمدید نوعیت حساب',
  },
  Branch: {
    Branch: `نمایندگی`,
    ParentName: `اسم پارتنر`,
    ModifiedOn: `تاریخ تمدید`,
    BranchList: `لیست نمایندگی`,
    Placeholder: `جستجو نمایندگی`,
    Address: `آدرس نمایندگی`,
    Parent: `نمایندگی مقام`,
    CreateBranch: `ایجاد نمایندگی`,
    EditBranch: `تمدید نمایندگی`,
    New: `نمایندگی جدید`,
    Update: `تمدید نمایندگی`,
    AddTitle: 'ثبت نمایندگی',
    UpdateTitle: 'تمدید نمایندگی',
  },
  PaymentType: {
    PaymentType: `نوعیت پرداخت`,
    ModifiedOn: `تاریخ تمدید`,
    PaymentTypeList: `لیست نوعیت پرداخت`,
    Placeholder: `جسنجو نوعیت پرداخت`,
    CreatePaymentType: `ایجاد نوعیت پرداخت`,
    EditPaymentType: `تمدید نوعیت پرداخت`,
    New: `نوعیت پرداخت جدید`,
    Update: `تمدید نوعیت پرداخت`,
    AddTitle: 'ثبت نوعیت پرداخت',
    UpdateTitle: 'تمدید نوعیت پرداخت',
  },
  Partners: {
    Partners: `پارتنر ها`,
    ModifiedOn: `تاریخ تمدید`,
    PartnersList: `لیست پارتنر `,
    Placeholder: `جستجو پارتنتر`,
    CreatePartners: `ایجاد پارتنر`,
    EditPartners: `تمدید پارتنر`,
    New: `پارتنر جدید`,
    Update: `تازه نمودن پارتنر`,
    AddTitle: 'ثبت پارتنر',
    UpdateTitle: 'تمدید پارتنر',
  },
  CurrencyType: {
    CurrencyType: `نوعیت واحد پول`,
    ModifiedOn: `تاریخ تمدید`,
    CurrencyTypeList: `لیست واحد پول`,
    Placeholder: `جستجو واحد پولی`,
    CreateCurrencyType: `ایجاد واحد پول`,
    EditCurrencyType: `تمدید واحد پول`,
    New: `واحد پول جدید`,
    Update: `تمدید واحد پول`,
    AddTitle: 'ایجاد واحد پول',
    UpdateTitle: 'تمدید واحد پول',
  },
  LoanType: {
    LoanType: `نوعیت قرضه`,
    Title: `نوعیت قرضه`,
    LoanTypeList: `لیست نوعیت قرضه`,
    Placeholder: `جستجو نوعیت قرضه`,
    CreateLoanType: 'ایجاد نوعیت قرضه',
    EditLoanType: 'تمدید نوعیت قرضه',
    New: 'نوعیت قرضه جدید',
    Update: 'تمدید نوعیت قرضه',
    AddTitle: 'ایجاد نوعیت قرضه',
    UpdateTitle: 'تمدید نوعیت قرضه',
  },
  Partner: {
    Partner: `پارتنر`,
    PartnerPhone: `نمبر پارتنر`,
  },
  Department: {
    Dashboard: 'ډ شبورډ',
  },
  TradeTracking: {
    TradeTracking: 'تاریخچه لیست ترید',
    Title: 'تاریخجه ترید',
    New: 'ترید جدید',
    Update: 'تمدید ترید',
    EnterTrade: 'ثبت ترید',
    TradeAmount: 'مقدار معامله ترید',
    ProfitAmount: 'مفاد از ترید',
    LossAmount: 'ضرر از ترید',
    CreateTradeTracking: 'ایجاد ترید',
    Placeholder: 'جتسجو ترید',
  },
  ExpenseTracking: {
    ExpenseTracking: 'تاریخچه لیست مصارفات',
    Title: 'تاریخچه مصارفات',
    New: 'مصارف جدید',
    Create: 'ایجاد مصرف',
    Edit: 'تمدید مصرف',
    Update: 'تمدید مصرف',
    EnterExpense: 'ثبت مصرف',
    ExpenseAmount: 'پول پرداخت مصارف',
    CreateExpenseTracking: 'ایجاد مصارف',
    Placeholder: 'جستجو مصارفات',
  },
  WithdrawalTracking: {
    WithdrawalTracking: ' لیست تاریخچه برداشت / واریز',
    Title: 'تاریخچه برداشت / واریز',
    New: 'ایجاد برداشت / واریز',
    Create: 'ایجاد برداشت',
    Edit: 'تمدید برداشت',
    CreateDeposit: 'ایجاد واریز',
    EditDeposit: 'تمدید واریز',
    DepositToAccount: 'واریز به حساب',
    Update: 'تمدید برداشت / واریز',
    EnterWithdrawal: 'مقدار برداش را وارد نمایید',
    WithdrawalAmount: 'مقدار برداشت',
    CreateWithdrawalTracking: 'ایجاد برداشت / واریز',
    Placeholder: 'جستجو برداشت / واریز',
  },
  ExpenseType: {
    ExpenseType: `نوعیت مصارف`,
    ExpenseTypeList: `لیست نوعیت مصارف`,
    Placeholder: `جستجو نوعیت مصارف`,
    CreateExpenseType: 'ایجاد نوعیت مصارف',
    EditExpenseType: `تمدید نوعیت مصارف`,
    New: `نوعیت مصارف جدید`,
    Update: `تمدید نوعیت مصارف`,
  },
  npxChartDashboard: {
    TotalUsers: `مجموعه یوزر ها`,
    TotalBracnhes: `مجموعه نماینده گی ها`,
  },
  //========================== Menu Translation ========================
  AssetManagementMenu: `مدیریت حساب`,
  LoanTrackingMenu: `مدیریت قرضه`,
  TradeTrackingMenu: `مدیرید ترید`,
  ExpenseTrackingMenu: `مدیریت مصارفات`,
  WithdrawalTrackingMenu: `مدیریت برداشت / واریز`,
  lookuptable: 'تنظیمات سیستم',
  BranchMenu: `نمایندگی`,
  ExpenseTypeMenu: `نوعیت مصارف`,
  AssetTypeMenu: `نوعیت حساب`,
  LoanTypeMenu: `نوعیت قرضه`,
  PaymentTypeMenu: `نوعیت پرداخت`,
  CurrencyTypeMenu: `نوعیت واحد پول`,
  PartnersMenu: `پارتنر ها`,
  // ========================= End Menu Translation =====================

  LoanTracking: {
    AddTitle: `ایجاد قرضه`,
    UpdateTitle: `تمدید قرضه`,
    CreateLoanTracking: `ایجاد قرضه جدید`,
    EditLoanTracking: `تمدید قرضه`,
    LoanTrackingList: `لیست قرضه ها`,
    New: `قرضه جدید`,
    Update: `تمدید قرضه`,
    LoanAmount: `مقدار قرض`,
    PaidAmount: `مقدار پرداخت شده`,
    RemainAmount: `مقدار باقی مانده`,
    Placeholder: `جتسجو قرضه ها`,
    GiveLoan: `قرض داده شده`,
    TakeLoan: `قرض گرفته شده`,
  },
  //Login Page
  login: {
    SignInToMinistry: 'به سیستم مدیریتی (NPFX) وارد شوید',
    WelcomeBack: 'خوش آمدید',
    UserName: 'نام کاربری',
    Password: 'رمز عبور',
    RememberMe: 'مرا به خاطر بسپار',
    Login: 'وارد شدن',
    ForgotPassword: 'رمز عبور را فراموش کرده اید؟',
    SendEmail:
      'لطفاً ایمیل آدرس مرتبط با حساب خود را وارد کنید و ما یک کود را به شما میفرستیم تا رمز عبور خویش را دوباره تنظیم نمائید',
    EmailMustBe: 'ایمیل آدرس تان باید درست باشد',
    EmailIsRequired: 'ایمیل آدرس الزامی میباشد',
    EmailAddress: 'ایمیل آدرس',
    SendRequest: 'ارسال درخواست',
    RequestSent: 'درخواست با موفقیت ارسال شده است!',
    WeHaveSent: 'ما یک کود تاییدی 4 رقمی را به ایمیل شما ارسال کرده ایم.',
    PleaseEnter: 'لطفاً کود را در باکس زیر وارد نمائید تا ایمیل خود را تایید کنید.',
    VerifyCode: 'کود را تایید نمائید',
    DontHaveACode: 'کود را دریافت نه نموده اید؟',
    ResendCode: 'ارسال دوباره کود',
    ConfirmNewPassword: 'تایید رمز عبور جدید',
    ChangePassword: 'تغیر نمودن رمز عبور',
    PasswordMustBe: 'رمز عبور باید حداقل 6 حرف باشد',
    PasswordIsRequired: 'رمز عبور الزامی میباشد',
    ConfirmPassword: 'تاییدی رمز عبور الزامی میباشد',
    PasswordMatch: 'زمز عبور باید یکسان باشد',
    EmailNotExist: 'ایمیل آدرس موجود نیست، لطفاً ایمیل آدرس خود را راجستر نمائید!',
    InvalidCode: 'کود درست نمیباشد، دوباره کوشش نمائید!',
  },

  Pagination: {
    RowsPerPage: 'تعداد سطر در هر صفحه',
    Dense: 'فشرده',
    Of: 'از',
  },

  Validation: {
    EnglishName: 'نام به انگلیسی الزامی میباشد',
    DariName: 'نام به دری الزامی میباشد',
    PashtoName: 'نام به پشتو الزامی میباشد',
    EnglishSurName: 'تخلص به انگلیسی الزامی میباشد',
    PashtoSurName: 'تخلص په پشتو الزامی میباشد',
    Contact: 'شماره تلفن را وارد کنید',
    Code: 'کود را وارد کنید',
    Type: 'نوعیت را وارد کنید',
    Language: 'زبان را وارد کنید',
    Remark: 'لطفا نظر تان را وارد کنید',
    Address: 'آدرس را وارد  کنید.',
    Name: 'لطفا نام را وارد کنید',
    ContactNumber1: 'لطفا شماره تلفن ۱ را وارد کنید.',
    ContactNumber2: 'لطفا شماره تلفن ۲ را وارد کنید.',
    Email: 'ایمیل آدرس ضرور میباشد.',
    Organization: 'انتخاب نهاد الزامی میباشد.',
    NoOfPage: 'لطفا عدد بزرگتر از 0 را وارد کنید',
    ReturnDate: 'تاریخ برګشت را وارد کنید',
    User: 'کاربر الزامی میباشد',
    Comments: 'نظریات الزامی میباشد',
    Description: 'توضیحات الزامی میباشد',
    FatherEnglishNameIsRequired: 'نام پدر به انګلیسی الزامی میباشد',
    FatherDariNameIsRequired: 'نام پدر به پشتو الزامی میباشد',
    GrandFatherEnglish: 'نام پدرکلان به انگلیسی الزامی میباشد',
    GrandFatherPashto: 'نام پدرکلان به پشتو الزامی میباشد',
    GenderIsRequired: 'لطفاً جنسیت را انتخاب نمائید',
    EmployeeType: 'لطفاً نوع کارمند را انتخاب نمائید',
    PositionIsRequired: 'بست الزامی میباشد',
    DegreeIsRequired: 'لطفاً درجه تحصیلی کارمند را انتخاب نمائید',
    CountryNameIsRequired: 'لطفاً نام کشور را انتخاب نمائید، الزامی میباشد',
    //
    DateOfBirth: 'تاریخ تولد الزامی میباشد',
    TemporaryAddress: 'آدرس فعلی الزامی میباشد',
    PermanentAddress: 'آدرس دایمی لازمی میباشد',
    AttendanceId: 'آی دی حاضری الزامی میباشد',
    CNIC: 'نمبر تذکره الزامی میباشد',
    Gender: 'لطفاً جنسیت را انتخاب نمائید، جنسیت الزامی میباشد',
    JoinDate: 'تاریخ مقرری الزامی میباشد',
    PersonalEmail: 'ایمیل آدرس شخصی الزامی میباشد',
    OfficialEmail: 'ایمیل آدرس رسمی الزامی میباشد',
    Province: 'لطفاً ولایت را انتخاب نمائید، ولایت الزامی میباشد',
    District: 'لطفاً ولسوالی را انتخاب نمائید، ولسوالی الزامی میباشد',
    BloodGroup: 'لطفاً گروپ را انتخاب نمائید، گروپ الزامی میباشد',
    LeaveDate: 'تاریخ مرخصی الزامی میباشد',
    LeaveRemarks: 'لطفاً جزئیات مرخصی را درج نمائید',
    isCurrent: 'لطفاً انتخاب نمائید که آیا فعلاً کارمند است؟',
    ProfilePhoto: 'تصویر پروفایل الزامی است',
    //EducationLevelDetails
    educationLevel: 'لطفاً درجه تحصیل را انتخاب نمائید، الزامی میباشد',
    educationTitle: 'لطفاً رشته تحصیلی را انتخاب نمائید، الزامی میباشد',
    englishUniName: 'نام مرجع تحصیلی به انگلیسی الزامی میباشد',
    pashtoUniName: 'نام مرجع تحصیلی به پشتو الزامی میباشد',
    dariUniName: 'نام مرجع تحصیلی به دری الزامی میباشد',
    comment: 'لطفاً نظر خویش را ارائه نمائید، الزامی میباشد',
    DoubleClick: 'دو بار کلیک نمائید تا جزئیات سند ',
    RequestDoubleClick: 'دو بار کلیک نمائید تا جزئیات درخواست  ',
    DocumentDetails: ' نمایان گردد',
    RequestDetails: 'نمایان گردد ',
    ClickHere: 'اینجا کلیک نمائید تا جزئیات برجسته گردد',
    //
    FromDate: 'از کدام تاریخ، انتخاب نمائید',
    ToDate: 'تا کدام تاریخ، انتخاب نمائید',
    //Grade
    ContractType: 'لطفاً نوع قرار داد را انتخاب نمائید، الزامی میباشد',
    ActionRequestDoubleClick: 'دو بار کلیک نمائید تا جزئیات  ',
    ActionRequestDetails: 'نمایان گردد',
    DistrictCode: 'کود ولسوالی الزامی میباشد',
    setDefault: 'لطفاً انتخاب نمائید که سال درج شده سال روان است یا خیر',
    EmployeeIsRequired: 'کارمند الزامی میباشد',
    ReportToDepartment: 'لطفاً به ریاست که راپور داده میشود انتخاب نمائید',
    Directorate: 'لطفاً ریاست را انتخاب نمائید، الزامی میباشد',
    TitleIsRequired: 'عنوان الزامی میباشد',
    // NPFX
    ExpenseType: 'نوعیت مصارف الزامی است',
    Account: 'صورت حساب الزامی است',
    Branch: 'نمایندگی الزامی است',
    Date: 'تاریخ الزاامی است',
    Amount: 'مقدار پول الزامی است',
    LoanAmount: 'مقدار قرضه الزامی',
    LoanType: 'نوعیت قرضه الزامی است',
    CurrencyType: 'نوعیت پول الزامی است',
    AccountType: 'نوعیت حساب الزامی است',
    OwnerUser: 'مالک حساب الزامی است',
    DepositDate: 'تاریخ واریز پول الزامی است',
    BalanceAmount: 'بلانس الزامی است',
    DepositAmmount: 'مقدار واریز الزامی است',
    ToUser: 'انتخاب کاربر الزامی است',
    TradeAmount: 'مقدار برای ترید الزامی است',
    WithdrawalAmount: 'مقدار برداشت الزامی است',
    // Property Validation
    PropertyIsRequired: 'ملکیت الزامی است',
    ConditionId: 'شرایط الزامی است',
    Category: 'دسته‌بندی الزامی است',
    Price: 'قیمت الزامی است',
    AmountPaid: 'مقدار پرداخت شده الزامی است',
    PaymentDate: 'تاریخ پرداخت الزامی است',
  },
  // Fields names which repeadedly uses in tables
  GeneralFields: {
    Id: 'آی دی',
    Name: 'نام',
    EnglishName: 'نام انگلیسی',
    PashtoName: 'نام پشتو',
    DariName: 'نام دری',
    Code: 'کود',
    Action: 'عملیات',
    StartDate: 'تاریخ آغاز',
    EndDate: 'تاریخ ختم',
    Remarks: 'جزئیات',
    Description: 'توضیحات',
    Date: 'تاریخ',
    SelectAll: 'انتخاب همه',
  },

  Tostar: {
    Success: '!موفقانه حذف ګردید',
    DeleteSuccess: '!موفقانه حذف ګردید',
    DeleteFailed: '!حذف نگردید',
    CreateSuccess: 'موفقانه ایجاد گردید!',
    UpdateSuccess: 'موفقانه تغییر گردید!',
    RejectSuccess: 'موفقانه رد ګردید!',
    RejectFaild: 'رد نګردید!',
    UserActivated: 'کاربر فعال گردید!',
    UserDeactivated: 'کاربر غیر فعال گردید!',
    DataIsAlreadyExist: 'دیتا از قبل موجود است ',
    LoginSuccess: '!موفقانه وارد سیستم گردیدید',
    TaskReAsign: 'عمل موفقانه سپرده شد',
    ApproveSuccess: 'موفقانه تایید گردید',
    NewTicket: 'فورم جدید',
    TicketCompleted: 'فورم تکمیل گردید',
    TicketRejected: 'فورم رد گردید',
    TicketReceived: 'فورم دریافت گردید',
    MarkedAsRead: 'بعنوان خوانده شده نشانی شد',
    MarkedAsUnRead: 'بعنوان خوانده نه شده نشانی شد!',
    ChangePassword: 'رمز عبور موفقانه تغیر گردید!',
    ReceivedSuccess: 'وارده گرفتن سند موفقانه صورت گرفت',
  },
  ProcessStatus: {
    Title: 'حالت پروسه: لیست',
    CreateTitle: 'حالت پروسه: ایجاد جدید',
    ProcessStatusList: 'لیست حالت پروسه ها',
    ProcessStatus: 'حالت پروسه ',
    Placeholder: 'حالت پروسه را جستجو کنید',
    NewProcessStatus: 'حالت پروسه جدید را اضافه  کنید',
    UpdateProcessStatus: 'حالت پروسه را تغییر دهید',
    CreateProcessStatus: 'حالت پروسه  را ایجاد کنید',
  },
  //Notifications
  Notifications: {
    Notifications: 'اطلاعیه ها',
    New: 'جدید',
    BeforeThat: 'قبلی',
    ViewAll: 'مشاهده کل',
    ViewMore: 'مشاهده بیشتر',
  },
  //HR Employee
  Employee: {
    Title: 'کارمند: لیست کارمندان',
    EmployeeList: 'لیست کارمندان',
    Id: 'شماره',
    Name: 'نام',
    FatherName: 'نام پدر',
    EmployeeType: 'نوع کارمند',
    Department: 'ریاست',
    Position: 'بست',
    Action: 'عملیات',
    Email: 'ایمیل آدرس',
    PhoneNumber: 'شماره  تماس',
    Placeholder: 'کارمند را به اساس نام، ریاست یا شماره تماس جستجو نمائید ...',
    CreateTitle: 'کارمند: ایجاد کارمند جدید',
    CreateEmployee: 'کارمند جدید اضافه نمائید',
    UpdateEmployee: 'کارمند را تغیر نمائید',
    NewEmployee: 'کارمند جدید',
    UpdateEmp: 'تغیر کارمند',
    EnglishName: 'نام به انگلیسی',
    DariName: 'نام دری (عنوان بست)',
    PashtoName: 'نام به پشتو',
    FatherEnglishName: 'نام پدر به انگلیسی',
    FatherDariName: 'نام پدر به پشتو',
    Gender: 'جنسیت',
    Male: 'ذکور',
    Female: 'اناث',
    Official: 'رسمی',
    Contractual: 'قراردادی',
    Intermittently: 'باالمقطع',
    JoinDate: 'تاریخ مقرری',
    EmployeeDegree: 'درجه تحصیلی',
    BloodGroup: 'گروپ خون',
    AttendanceId: 'آی دی حاضری',
    Details: 'جزئیات',
    CreateUser: 'اکونت را اضافه نمائید',
    ContractDetails: 'جزئیات قرارداد',
    CardDetails: 'جزئیات کارت',
    EducationalLevelDetails: 'درجه تحصیل',
    EnglishBioData: 'معلومات بیوگرافی به انگلیسی',
    PashtoDariBioData: 'معلومات بیوگرافی به پشتو/دری',
    ProfilePhoto: 'عکس پروفایل',
    CropImage: 'برش تصویر',
    TazkiraType: 'نوعیت تذکره',
    ETazkira: 'تذکره الکترونیکی',
    PaperBaseTazkira: 'تذکره کاغذی',
    JoldNo: 'شماره جلد',
    PageNo: 'شماره صفحه',
    RegNo: 'شماره ثبت',
    //
    englishSurName: 'تخلص به انگلیسي',
    pashtoSurName: 'تخلص به پشتو',
    englishGrandFatherName: 'نام پدرکلان به انگلیسي',
    pashtoGrandFatherName: 'نام پدرکلان به پشتو',
    dateOfBirth: 'تاریخ تولد',
    temporaryAddress: 'آدرس فعلی',
    permanentAddress: 'آدرس دایمی',
    attendanceId: 'آی دی حاضری',
    cnic: 'نمبر تذکره',
    leaveDate: 'تاریخ مرخصی',
    leaveRemark: 'جزئیات مرخصی',
    personalEmail: 'ایمیل آدرس شخصی',
    officialEmail: 'ایمیل آدرس رسمی',
    rfidNumber: 'نمبر RFID',
    isCurrent: 'آیا فعلاً کارمند است',
    IsCurrentContract: 'حالت قرارداد',
    emergencyPhoneNumber: 'شماره تماس اضطراری',
    registrationNumber: 'نمبر راجستر',
    Province: 'ولایت',
    District: 'ولسوالی',
    Employee: 'کارمند',
    DoubleClick: 'دو بار کلیک نمائید تا جزئیات ',
    EmployeeDetail: 'نمایان گردد ',
    //EmployeeDetails
    DetailsTitle: 'کارمند: جزئیات کارمند',
    EmployeeDetails: 'جزئیات کارمند',
    EmployeeHealthStatus: 'حالت صحی کارمند',
    EmployeeGeneralInfo: 'معلومات عمومی کارمند',
    //Employee Report
    EmployeeReportTitle: 'کارمند: راپور کارمندان',
    EmployeeReport: 'راپور کارمندان',
    HasAccount: 'حساب کاربردی دارد',
    hasAccount: 'دارد',
    NoAccount: 'ندارد',
    FullName: 'نام مکمل',
    Account: 'حساب کاربردی',
  },

  //ContractDetails
  ContractDetails: {
    Title: 'جزئیات قرارداد: لیست',
    ContractDetailsList: 'لیست جزئیات قرارداد',
    Placeholder: 'جزئیات قرارداد را جستجو نمائید...',
    AddTitle: 'جزئیات قرارداد: اضافه نمودن',
    CreateContractDetails: 'جزئیات قرارداد را اضافه نمائید',
    UpdateTitle: 'جزئیات قرارداد: تغیر نمودن',
    EditContractDetails: 'جزئیات قرارداد را تغیر نمائید',
    New: 'اضافه نمودن جزئیات قرارداد',
    Update: 'تغیر نمودن جزئیات قرارداد',
    Active: 'برحال',
    ContractWarning:
      'هشدار: دکمه ذخیره کردن غیرفعال است زیرا قرارداد فعلی کارمند به پایان نرسیده است، لطفاً قرارداد فعلی کارمند را به روز کنید.',
  },
  //CardDetails
  CardDetails: {
    Title: 'جزئیات کارت: لیست',
    CardDetailsList: 'لیست جزئیات کارت',
    Placeholder: 'جزئیات کارت را جستجو نمائید...',
    AddTitle: 'جزئیات کارت: اضافه نمودن',
    CreateCardDetails: 'جزئیات کارت را اضافه نمائید',
    UpdateTitle: 'جزئیات کارت: تغیر نمودن',
    EditCardDetails: 'جزئیات کارت را تغیر نمائید',
    New: 'اضافه نمودن جزئیات کارت',
    Update: 'تغیر نمودن جزئیات کارت',
    IsPreviosCardReturned: 'آیا کارت قبلی برگردانده شده است',
    Branch: ' شعبه',
    currencyType: ' نوعیت پول',
    salaryAmount: ' مقدار معاش',
    positionTitle: ' عنوان وظیفه',
  },
  Categorys: {
    Categorys: 'دسته‌ها',
    Title: 'دسته‌ها',
    CategorysList: 'فهرست انواع دسته‌ها',
    Placeholder: 'در اینجا دسته‌ها را جستجو کنید',
    CreateCategorys: 'ایجاد دسته‌ها',
    EditCategorys: 'ویرایش دسته‌ها',
    New: 'دسته‌های جدید',
    Update: 'بروزرسانی دسته‌ها',
    AddTitle: 'اضافه کردن دسته‌ها',
    UpdateTitle: 'بروزرسانی دسته‌ها',
  },
  PropertyConditions: {
    PropertyConditions: 'شرایط ملکیت',
    Title: 'شرایط ملکیت',
    PropertyConditionsList: 'فهرست انواع شرایط ملکیت',
    Placeholder: 'در اینجا شرایط ملکیت را جستجو کنید',
    CreatePropertyConditions: 'ایجاد شرایط ملکیت',
    EditPropertyConditions: 'ویرایش شرایط ملکیت',
    New: 'شرایط ملکیت جدید',
    Update: 'بروزرسانی شرایط ملکیت',
    AddTitle: 'اضافه کردن شرایط ملکیت',
    UpdateTitle: 'بروزرسانی شرایط ملکیت',
  },
  Property: {
    AddTitle: 'ایجاد ملکیت',
    PropertyList: 'فهرست ملکیت‌ها',
    Condition: 'حالت',
    UpdateTitle: 'بروزرسانی ملکیت',
    DetailsTitle: 'جزئیات ملکیت',
    New: 'ملکیت جدید',
    Update: 'بروزرسانی ملکیت',
    Employee: 'نام کارمند',
    PropertyConditions: 'شرایط ملکیت',
    Model: 'مدل',
    PaymentDate: 'تاریخ پرداخت',
    Payments: 'پرداخت‌ها',
    Payment: 'پرداخت',
    Price: 'قیمت',
    Assignments: 'وظایف',
    Assignment: 'وظیفه',
    AmountPaid: 'مبلغ پرداخت شده',
    PayableAmount: 'مبلغ قابل پرداخت',
    RemainingBalance: 'باقی مانده',
    MaintenanceHistory: 'تاریخچه نگهداری',
    Placeholder: 'اینجا جستجو کنید ....',
  },

  //Employee Degree
  EmployeeDegree: {
    Title: 'درجه تحصیل کارمندان: لیست درجه تحصیل',
    EmployeeDegreeList: 'لیست درجه تحصیل کارمندان',
    Placeholder: 'درجه تحصیل کارمندان را جستجو نمائید',
    CreateTitle: 'درجه تحصیل کارمندان: درجه تحصیل جدید',
    CreateEmployeeDegree: 'درجه تحصیل جدید کارمندان را اضافه نمائید',
    EditEmployeeDegree: 'درجه تحصیل کارمندان را تغیر نمائید',
    NewEmployeeDegree: 'درجه تحصیل جدید کارمند',
    UpdateEmployeeDegree: 'تغیر درجه تحصیل کارمند',
  },
  //Employee Position
  EmployeePosition: {
    Title: 'بست کارمندان: لیست بست های کارمندان',
    EmployeePositionList: 'لیست بست های کارمندان',
    Placeholder: 'جستجو بست های کارمندان',
    CreateTitle: 'بست کارمندان: اضافه نمودن بست کارمند',
    CreateEmployeePosition: 'بست جدید کارمند را اضافه نمائید',
    EditEmployeePosition: 'بست کارمند رانمائید',
    NewEmployeePosition: 'بست جدید کارمندان',
    UpdateEmployeePosition: 'تغیر بست کارمندان',
  },
  HRDashboard: {
    HRDashboard: 'دشبورد منابع بشری',
    TotalEmployeesHasUser: 'مجموع کارمندان که حساب کاربری دارند',
    TotalActiveEmployeeByDepartments: 'مجموع کارمندان فعال نظر به دیپارتمنت',
  },

  Dashboard: {
    MISWorkDashboard: 'دشبورد کارهای MIS',
    ReceptionDashboard: 'دشبورد پذیرش',
    ArchiveDashboard: ' دشبورد آرشیف',
    InternalDocumentDashboard: 'دشبورد اسناد داخلی',
    ExternalDocumentDashboard: 'دشبورد اسناد خارجی',
    UserPerformanceDashboard: 'دشبورد فعالیت های کاربر',
    ITSMSDashboard: 'دشبورد IT-SMS',
    UserSummaryDashboard: 'دشبورد خلاصه کاربر',
  },

  ProcessName: {
    All: 'همه',
    Completed: 'تکمیل شده',
    InProcess: 'در حال اجرأ',
    Pending: 'در حال انتظار',
    NotCompleted: 'تکمیل ناشده',
    Approve: 'تصدیق',
    Reject: 'مسترد',
    DocumentProcessEnd: 'ختم پروسه سند',
    ReplytoReceivedDocument: 'جواب سند وارده',
    ExecuteandMessageSending: 'اجرا و ارسال پیغام',
    ProcessStart: 'شروع پروسه',
    Sent: 'ارسال شده',
    Received: 'دریافت شده',
    DueGiven: 'قرض های داده شده',
    DueTaken: 'قرضه های ګرفته شده',
  },

  list: 'لیست',
  create: 'ایجاد کردن',
  externaldocument: 'اسناد خارجی',
  ums: 'سیستم مدیریت کاربران',
  APPlication: {
    App: 'سیستم',
    AppList: 'لیست سیستم ها',
    Placeholder: 'سیستم را جستجو نمایید',
    Title: 'عنوان',
    Id: 'آی دی',
    Action: 'عملیات',
    Abbrevation: 'اختصاریه',
    IconClass: 'آیکن کلاس',
    DefaultRoute: 'دیفالت روت',
    Area: 'ساحه',
    Description: 'توضیحات',
    NewApp: 'سیستم جدید',
    UpdateApp: 'تغییر سیستم',
    CreateApp: 'ایجاد سیستم',
  },
  province: 'ولایت',
  district: 'حوزه',
  //HR
  HRLookupTables: 'سیستم منابع بشری',
  contractType: 'نوع قرارداد',

  educationTitle: 'رشته تحصیلی',
  educationLevel: 'درجه تحصیلی',

  grade: 'بست',
  gradeStep: 'قدم بست',
  positionTitle: 'عنوان وظیفه',
  jobPosition: 'عنوان بست',
  employeeHealthStatus: 'حالت صحی کارمند',
  cardPrint: 'چاپ کارت',
  //CommonLookUps
  CommonLookUps: 'عمومی',
  //News
  news: 'اطلاعیه ها',
  newsList: 'لیست اطلاعیه ها',
  addNews: 'اضافه نمودن اطلاعیه',
  //Training Videos
  trainingVideo: 'ویدیو ها آموزشی',
  addTrainingVideo: 'اضافه نمودن ویدیو',
  trainingVideolist: 'لیست ویدیو',
  trainingVideoPlayer: 'نمایش ویدیو',

  //UMS
  UMS: 'سیستم مدیریتی کاربران',
  UserManagement: 'مدیریت کاربران',
  Permission: 'اجازه',
  Role: 'رول',
  createUser: 'ایجاد کاربر',
  Userlist: 'لیست کاربران',
  UserLogList: 'تاریخچه کاربران',
  changePassword: 'تغیر رمز عبور',
  //Pending Requests
  PendingRequests: {
    Title: 'درخواست های منتظر شده',
    List: 'لیست درخواست های منتظر شده',
    Placeholder: 'به اساس شماره تعقیب، شماره درخواست یا کاربر جستجو نمائید...',
    ReAsign: 'سپردن دوباره',
    ReAsignTitle: 'آیا میخواهید دوباره بسپارید؟',
  },

  //User Management
  User: {
    user: 'کاربر',
    newUser: 'کاربر جدید',
    userList: 'لیست کاربران',
    userName: 'نام کاربر',
    fullUserName: 'نام مکمل کابر',
    email: 'ایمیل آدرس',
    isActive: 'فعال است',
    Active: 'فعال است',
    Branch: 'نماینده گی',
    unActive: 'غیر فعال است',
    Banned: 'غیر فعال است',
    createUser: 'کاربر جدید را اضافه نمائید',
    editUser: 'تغیر نمودن کاربر',
    Placeholder: 'جستجو کاربر ...',
    phoneNumber: 'شماره تماس',
    positionTitle: 'عنوان بست',
    Language: 'لسان',
    userRoles: 'نقش کاربر',
    AllowedDepartments: 'ریاست های مجاز',
    AllowedSecurityLevels: 'سطح امنیتی مجاز',
    AllowedDocumentType: 'انواع سند مجاز',
    Organization: 'اداره',
    CreateUser: 'کاربر را اضافه',
    UpdateChanges: 'کاربر را تغیر نمائید',
    Allowed: 'اجازه دارد *.jpeg, *.jpg, *.png',
    MaxSize: 'حداکثر سایز',
    UploadPhoto: 'عکس را اضافه نمائید',
    UpdatePhoto: 'عکس را تغیر نمائید',
    NoPositionAdded: 'هیچ بست اضافه نگردیده است',
    SecurityLevel: 'سطح امنیت',
    UserDetails: 'جزئیات کاربر',
    ResetPassword: 'بازنشانی رمز عبور',
    NewPassword: 'رمز عبور جدید',
    ChangePassword: 'تغیر رمز عبور',
    CurrentPassword: 'رمز عبور فعلی',
    ConfirmNewPassword: 'رمز عبور جدید را تایید نمائید',
    Title: 'کاربر: لیست کاربران',
    active: 'فعال',
    Deactive: 'غیر فعال',
    AppName: 'نام اپلیکیشن',
    Description: 'توضیحات',
    EnglishName: 'نام انگلیسی',
    Code: 'کود',
    PashtoName: 'نام پشتو',
    DariName: 'نام دری',
    About: 'معلومات مرتبط',
    Contact: 'شماره تماس',
    GeneralInfo: 'معلومات عمومی',
    ProfileTitle: 'کاربر: پروفایل',
    Profile: 'پروفایل کاربر',
    UserPosition: 'عنوان بست کاربر',
    Employee: 'کارمند',
    Representator: 'نماینده',
    userPassword: 'رمز کاربر',
    department: 'ریاست',
    isManager: 'آیا مدیر اجرائیه است',
    Action: 'عمل',
    ActionOn: 'تاریخ عمل',
    IpAddress: 'آی پي آدرس',
    Message: 'پیام',
    Server: 'سرور',
    BrowserName: 'نام بروزر',
    Os: 'سیستم عملیاتي',
    userLogTitle: 'تاریخچه کاربران: لیست',
    userLogList: ' لیست تاریخچه کاربران',
    ActiveUsers: 'کاربران فعال',
    Managers: 'مدیران اجرائیه',
    ToggleOrientation: 'تغیر شکل صفحه',
    UserReport: 'راپور کاربران',
    Day: 'روز',
    // Validation
    PleaseEnterNewPassword: 'لطفاً رمز عبور جدید را وارد نمائید',
    UserNameIsRequired: 'نام کاربر ضروری است',
    EmailIsRequired: 'ایمیل آدرس کاربر ضروری است',
    NumberFormat: '+937xxxxxxxx فارمت شماره باید قرار ذیل باشد',
    PhoneNumberIsRequired: 'شماره تماس ضروری است',
    PositionTitleIsRequired: 'عنوان بست ضروری است',
    LanguageIsRequired: 'لسان ضروری است',
    PleaseEnterRmarks: 'لطفاً دلیل حذف نمودن را وارد نمائید',
    CurrentPassIsRequired: 'رمز عبور فعلی ضروری است',
    AtLeastChar: 'حداقل رمز عبور باید 6 حرف باشد',
    NewPassIsRequired: 'رمز عبور جدید ضروری است',
    passMustMatch: 'رمز های عبور باید مطابقت داشته باشند',
    PasswordIsRequired: 'رمز عبور الزامی میباشد',
    NewPassRequiresAlpha: 'رمز عبور باید حروف داشته باشد',
    NewPassRequiresNumber: 'رمز عبور باید اعداد داشته باشد',
    NewPassRequiresSpecialChar: 'رمز عبور باید حروف خاص داشته باشد',
  },
  //Account
  Account: {
    Home: 'صفحه اصلی',
    Profile: 'پروفایل',
    Logout: 'خارج شدن',
  },
  //Role
  userRole: {
    Id: 'آی ډي',
    Application: 'آپلیکیشن',
    Name: 'نام',
    Description: 'توضیحات',
    Permission: 'اجازه',
    TotalPermissions: 'تمام اجازه ها',
    PermissionName: 'نام اجازه',
    ControllerName: ' نام کنترولر',
    Method: 'میتود',
    RoleList: 'لیست رول ها',
    Role: 'رول',
    Placeholder: 'جستجو رول ...',
    CreateNewRole: 'رول جدید ایجاد نمائید',
    EditRole: 'رول را تغیر بدهید',
    NewRole: 'رول جدید',
    RoleEdit: 'تغیر رول',
    CreateRole: 'رول را ایجاد نمائید',
    UpdateRole: 'رول را تغیر نمائید',
    RoleDetails: 'جزئیات رول',
    RoleName: 'نام رول',
    PermissionDetails: 'توضیحات اجازه',
    //Validation
    NameIsRequired: 'نام ضروری است',
    AppIsRequired: 'اپلیکیشن ضروری است',
    DescriptionIsRequired: 'توضیحات ضروری است',
  },
  //Permission
  userPermission: {
    ApplicationName: 'نام آپلیکیشن',
    isActionCategoryMissing: 'کتگوری عملیات نیست',
    Controller: 'کنترولر',
    ActionCategory: 'کتگوری عملیات',
    SaveAll: 'همه را ذخیره کنید',
    IsGlobal: 'گلوبل است',
    Id: 'آی دی',
    Name: 'نام',
    PermissionController: 'کنترولر',
    Action: 'عملیات',
    Method: 'میتود',
    Application: 'آپلیکیشن',
    PermissionList: 'لیست اجازه',
    Placeholder: 'جستجو اجازه ...',
  },
  //Province
  Province: {
    Title: 'ولایت: لیست ولایات',
    ProvinceList: 'لیست ولایات',
    Province: 'ولایت',
    Code: 'کود ولایت',
    Placeholder: 'ولایت را اینجا جستجو نمائید ...',
    AddTitle: 'ولایت: اضافه نمودن ولایت جدید',
    CreateNewProvince: 'ولایت جدید اضافه نمائید',
    UpdateProvince: 'ولایت را تغیر بدهید',
  },
  //District
  District: {
    Title: 'حوزه: لیست حوزه ها',
    DistrictList: 'لیست حوزه ها',
    District: 'حوزه',
    Code: 'کود حوزه',
    Placeholder: 'حوزه را اینجا جستجو نمائید ...',
    AddTitle: 'حوزه: اضافه نمودن حوزه جدید',
    CreateNewDistrict: 'یک حوزه جدید اضافه نمائید',
    UpdateDistrict: 'حوزه را تغیر نمائید',
    EnglishName: 'نام انگلیسی',
    PashtoName: 'نام پشتو',
    DariName: 'نام دری',
    UpdateTitle: 'حوزه: تغیر نمودن حوزه',
  },
  //HR Module
  //Education Title
  EducationTitle: {
    Title: 'رشته تحصیلی: لیست',
    AddTitle: 'رشته تحصیلی: ایجاد نمودن',
    UpdateTitle: 'رشته تحصیلی: تغیر نمودن',
    educationTitleList: 'لیست رشته تحصیلی',
    educationTitle: 'رشته تحصیلی',
    dashboard: 'دشبورد',
    iD: 'ای دی',
    engName: 'نام انګلیسی',
    dariName: 'نام دری',
    pashtoName: 'نام پشتو',
    code: 'کود',
    action: 'عملیات',
    remarks: 'لطفاْ ملاحظه خود را نوشته کنید',
    searchPlaceHolder:
      'رشته تحصیلی را را به اساس نام انگلیسی، نام پشتو، نام دری یا کود جستجو نمائید...',
    updateEducationTitle: 'رشته تحصیل را تغیر دهید',
    newEducationTitle: 'رشته تحصیل جدید را اضافه نمائید',
    New: 'اضافه نمودن رشته تحصیلی',
    Update: 'تغیر نمودن رشته تحصیلی',
  },

  EducationLevel: {
    Title: 'درجه تحصیلی: لیست',
    AddTitle: 'درجه تحصیلی: ایجاد نمودن',
    UpdateTitle: 'درجه تحصیلی: تغیر نمودن',
    educationLevelList: 'لیست درجه تحصیلی',
    educationLevel: 'درجه تحصیلی',
    dashboard: 'دشبورد',
    iD: 'ای دی',
    engName: 'نام انګلیسی',
    dariName: 'نام دری',
    pashtoName: 'نام پشتو',
    code: 'کود',
    action: 'عمل',
    remarks: 'لطفا ملاحظه خود را نوشته کنید',
    searchPlaceHolder: 'درجه تحصیلی را به اساس نام انگلیسی، نام پشتو، نام دری یا کود جستجو نماید',
    updateEducationLevel: 'درجه تحصیل را تغیر بدهد',
    newEducationLevel: 'درجه تحصیل جدید را اضافه نماید',
    New: 'اضافه نمودن درجه تحصیلی',
    Update: 'تغیر نمودن درجه تحصیلی',
    Country: 'کشور',
  },

  //EducationLevelDetails
  EducationLevelDetails: {
    Title: 'جزئیات درجه تحصیل: لیست',
    CreateTitle: 'جزئیات درجه تحصیل: اضافه نمودن',
    UpdateTitle: 'جزئیات درجه تحصیل: تغیر نمودن',
    EducationLevelDetailsList: 'لیست جزئیات درجه تحصیل',
    Create: 'جزئیات جدید درجه تحصیل را اضافه نمائید',
    Edit: 'جزئیات درجه تحصیل را تغیر نمائید',
    EmployeeName: 'نام کارمند',
    UniversityName: 'نام مرجع تحصیلی',
    New: 'اضافه نمودن جزئیات درجه تحصیل',
    Update: 'تغیر نمودن جزئیات درجه تحصیل',
    Placeholder: 'جزئیات درجه تحصیل را اینجا جستجو نمائید...',
    EnglishUniName: 'نام مرجع تحصیلی به انگلیسی',
    PashtoUniName: 'نام مرجع تحصیلی به پشتو',
    DariUniName: 'نام مرجع تحصیلی به دری',
  },
  //ContractType
  ContractType: {
    Title: 'نوع قرارداد: لیست',
    ContractTypeList: 'لیست نوع قرارداد',
    Placeholder: 'نوع قرارداد را به اساس نام انگلیسی، نام پشتو، نام دری یا کود جستجو نمائید...',
    AddTitle: 'نوع قرارداد: ایجاد کردن',
    CreateContractType: 'یک نوع قرارداد جدید اضافه نمائید',
    UpdateTitle: 'نوع قرارداد: تغیر دادن',
    EditContractType: 'نوع قرارداد را تغیر نمائید',
    New: 'اضافه نمودن نوع قرارداد',
    Update: 'تغیر نمودن نوع قرارداد',
    ContractType: 'نوع قرارداد',
    Grade: 'بست',
    ContractStatus: 'حالت قرارداد',
    Current: 'فعـلی',
    Ended: 'ختم گردیده',
    EmployeeIsPermanent: 'کارمند دایمی است',
  },

  //Grade
  Grade: {
    Title: 'بست: لیست بست ها',
    GradeList: 'لیست بست ها',
    Placeholder: 'بست ها را به اساس نام انگلیسی، نام پشتو، نام دری یا کود جستجو نمائید...',
    AddTitle: 'بست: ایجاد کردن بست',
    CreateGrade: 'یک بست جدید اضافه نمائید',
    UpdateTitle: 'بست: تغیر نمودن بست',
    EditGrade: 'بست را تغیر نمائید',
    New: 'اضافه نمودن بست',
    Update: 'تغیر نمودن بست',
  },

  //GradeStep
  GradeStep: {
    Title: 'قدم بست: لیست قدم های بست',
    GradeStepList: 'لیست قدم های بست',
    Placeholder: 'قدم های بست را به اساس نام انگلیسی، نام پشتو، نام دری یا کود جستجو نمائید',
    AddTitle: 'قدم بست: اضافه نمودن قدم بست',
    CreateGradeStep: 'یک قدم بست جدید اضافه نمائید',
    UpdateTitle: 'قدم بست: تغیر نمودن قدم بست',
    EditGradeStep: 'قدم بست را تغیر نمائید',
    New: 'اضافه نمودن قدم بست',
    Update: 'تغیر نمودن قدم بست',
    GradStep: 'قدم بست',
  },
  //PositionTitle
  PositionTitle: {
    Title: 'عنوان وظیفه: لیست',
    PositionTitleList: 'لیست عناوین وظیفه',
    Placeholder: 'عنوان وظیفه را به اساس نام انگلیسی، نام پشتو، نام دری یا کود جستجو نمائید...',
    AddTitle: 'عنوان وظیفه: اضافه نمودن',
    CreatePositionTitle: 'یک عنوان وظیفه جدید اضافه نمائید',
    UpdateTitle: 'عنوان وظیفه: تغیر نمودن',
    EditPositionTitle: 'عنوان وظیفه را تغیر نمائید',
    New: 'اضافه نمودن عنوان وظیفه',
    Update: 'تغیر نمودن عنوان وظیفه',
    PositionTitle: 'عنوان وظیفه',
  },

  //JobPosition
  JobPosition: {
    Title: 'عنوان بست: لیست عنواین بست',
    JobPositionList: 'لیست عنواین بست',
    Placeholder: 'عنوان بست را به اساس نام انگلیسی، نام پشتو، نام دری یا کود جستجو نمائید...',
    AddTitle: 'عنوان بست: اضافه نمودن عنوان بست',
    CreateJobPosition: 'یک عنوان بست جدید اضافه نمائید',
    UpdateTitle: 'عنوان بست: تغیر نمودن عنوان بست',
    EditJobPosition: 'تغیر نمودن عنوان بست',
    New: 'اضافه نمودن عنوان بست جدید',
    Update: 'تغیر نمودن عنوان بست',
    JobPosition: 'عنوان بست',
    department: 'ریاست',
  },

  //News
  News: {
    ListTitle: 'اطلاعیه ها: لیست',
    List: 'لیست اطلاعیه ها',
    News: 'اطلاعیه ها',
    CreateTitle: 'اطلاعیه ها: اضافه نمودن',
    CreateNews: 'یک اطلاعیه جدید اضافه نمائید',
    NewsDate: 'تاریخ اطلاعیه',
    Title: 'عنوان',
    Description: 'محتویات',
    SelectDepartment: 'انتخاب ریاست ها',
    AddAttachments: 'ضمیمه اضافه نمائید',
    Placeholder: 'لطفاً اطلاعیه را به اساس عنوان، محتویات و گوینده جستجو نمائید...',
    Speaker: 'گوینده',
    Location: 'محل',
    UpdateTitle: 'اطلاعیه ها: تغیر نمودن',
    UpdateNews: 'اطلاعیه را تغیر نمائید',
    Create: 'اضافه نمودن اطلاعیه',
    Update: 'تغیر نمودن اطلاعیه',
    SelectAll: 'انتخاب همه',
    ShowNotification: 'نمایش هشدار اطلاعیه',
    True: 'بلی',
    False: 'نخیر',
    WriteContentsHere: 'محتویات را اینجا درج نمائید...',
    StartTime: 'وقت شروع',
    EndTime: 'وقت ختم',
    FreshNews: 'اطلاعیه های جدید',
    Download: 'داونلود',
  },
  //Welcome
  Welcome: {
    Title: 'به سیستم مدیریتی آب و انرژی افغانستان (AWEES) خوش آمدید',
    Text: 'وزارت انرژی و آب افغانستان  مسئول تمام پالیسی ها، مقررات، پروژه های انکشافی و هماهنگی بخش آب و انرژی در امارت اسلامی افغانستان است.',
  },

  Npfx: {
    NpfxWellcome: 'به سیستم مدیریت شرکت NPFX خوش آمدید',
    NPFXMarket: 'مارکیت NPFX',
    TodayTotalExpense: 'مجموع مصارف امروز',
    CurrentBalance: 'بیلانس فعلی',
    TodayTotalLoss: 'مجموع ضرر امروز',
    TodayTotalProfit: 'مجموع سود امروز',
    TodayTotalTrade: 'مجموع تجارت امروز',
    TradeChart: 'نمودار تجارت',
    Profit: 'سود',
    Loss: 'ضرر',
    Day: 'روز',
    Week: 'هفته',
    Month: 'ماه',
    Year: 'سال',
    Period: 'دوره',
    DataPoints: 'نقاط داده',
    Categories: 'دسته‌بندی‌ها',
    TotalAmount: 'مجموع مقدار',
    ExpensesByCategories: 'مصارف بر اساس دسته‌بندی‌ها',
    MemberBalance: 'بیلانس اعضا',
  },
  Report: {
    FromDate: 'از تاریخ',
    ToDate: 'تا تاریخ',
    Dashboard: 'داشبورد',
    ExpenseReport: 'گزارش مصارف',
    ExpenseTransaction: 'معامله مصارف',
    LoanReport: 'گزارش قرض',
    LoanTransaction: 'معامله قرض',
    TradeReport: 'گزارش تجارت',
    TradeTransaction: 'معامله تجارت',
    Filter: 'فیلتر',
    Print: 'چاپ',
    Reset: 'تنظیم مجدد',
    GrandTotal: 'مجموع کل',
    No: 'شماره',
    Branch: 'شاخه',
    LoanType: 'نوع قرض',
    CurrencyType: 'نوعیت ارز',
    Amount: 'مقدار',
    Paid: 'پرداخت شده',
    Remain: 'باقی مانده',
    Taken: 'گرفته شده',
    Given: 'داده شده',
    MainAssetCode: 'کد اصلی دارایی',
    UserName: 'نام کاربر',
    Date: 'تاریخ',
    Description: 'توضیحات',
    ExpenseType: 'نوع مصارف',
    Dollor: 'دالر',
    Afghani: 'افغانی',
    ProfitAmount: 'مقدار سود',
    LossAmount: 'مقدار ضرر',
    debitAmount: 'مقدار بدهی',
    creditAmount: 'مقدار اعتبار',
    balanceAmount: 'مقدار توازن',
    transactionDate: 'تاریخ معامله',
    MainAssetReport: 'گزارش دارایی اصلی',
    Partner: 'شریک',
    partnerPhone: 'شماره تماس شریک',
    loanAmount: 'مقدار قرض',
    paidAmount: 'مقدار پرداخت شده',
    remainAmount: 'مقدار باقی مانده',
    expenseType: 'نوع مصرف',
    tradeAmount: 'مقدار تجارت',
    profitAmount: 'مقدار منفعت',
    lossAmount: 'مقدار ضرر',
  },
  AssetDetails: {
    MainAssetReport: 'گزارش دارایی اصلی',
    AccountDetails: 'جزئیات حساب',
    TotalCreditAmount: 'مقدار کل اعتبار',
    TotalDebitAmount: 'مقدار کل بدهی',
    CurrentBalance: 'موجودی فعلی',
    AssetTracking: 'پیگیری دارایی',
    LoanTracking: 'پیگیری قرض',
    ExpenseTracking: 'پیگیری هزینه',
    TradeTracking: 'پیگیری تجارت',
    WidthrawalTracking: 'پیگیری برداشت',
    MembersBalance: 'موجودی اعضا',
  },
  EmployeeProperty: {
    PopertyDetails: 'جزئیات ملکیت',
    EmployeePropertyDetails: 'جزئیات ملکیت کارمند',
    Name: 'نام',
    Category: 'کتگوری',
    Model: 'مودل',
    Price: 'قیمت',
    Details: 'جزئیات',
  },

  apps: 'اپلیکیشن ها',
  Application: `اپلیکیشن`,
  user: `کارمند`,
  edit: `تعديل`,
  shop: `متجر`,
  blog: `مقالات`,
  post: `بريد`,
  mail: `بريد`,
  chat: `دردشة`,
  cards: `البطاقات`,
  posts: `المشاركات`,
  kanban: `كانبان`,
  general: `عمومیات`,
  banking: `الخدمات المصرفية`,
  booking: `بکنگ`,
  profile: `الملف الشخصي`,
  account: `الحساب`,
  product: `المنتوج`,
  invoice: `فاتورة`,
  details: `تفاصيل`,
  checkout: `الدفع`,
  calendar: `التقويم`,
  analytics: `التحليلات`,
  ecommerce: `التجارة الإلكترونية`,
  management: `مدیریت`,
  menu_level_1: `مستوى القائمة 1`,
  menu_level_2: `مستوى القائمة 2`,
  menu_level_3: `مستوى القائمة 3`,
  item_disabled: `العنصر معطل`,
  item_label: `تسمية العنصر`,
  item_caption: `عنوان العنصر`,
  description: `وصف`,
  other_cases: `حالات اخرى`,
  item_by_roles: `عنصر حسب الأدوار`,
  only_admin_can_see_this_item: `يمكن للمسؤول فقط رؤية هذا العنصر`,
  attendance: 'حاضری',
  hr: 'منابع بشری',
  emp: 'کارمند',
  employeeDegree: 'درجه تحصیلی کارمند',
  employeePosition: 'بست کارمند',
  report: 'گزارشات',
  NoRecordFound: 'هیچ ریکارد ای یافت نشد',
  categorys: 'دسته‌ها',
  propertyConditions: 'شرایط ملک',
  PropertyMenu: 'مدیریت ملکیت',
};

export default ps;
