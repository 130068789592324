// ----------------------------------------------------------------------

//import { update } from 'lodash';

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const dr = {
  demo: {
    title: `Pashto`,
    introduction: `پیژندنه`,
  },
  //Dashboard: {
  //BackToList: 'لیست ته ورګرځیدل',
  // },
  GoogleAuth: {
    RecoveryCode: 'د بیا ترلاسه کولو کوډ',
    loginGoogleAuth: 'د Google Authenticator کوډ سره ننوتل',
    loginRecovery: 'د بیا ترلاسه کولو کوډ سره ننوتل',
    scan: 'دا QR کوډ د Google Authenticator سره اسکن کړئ',
    enterSixDigit: 'شپږ عددي کوډ داخل کړئ',
    verify: 'تاییدول',
    RecoveryCodes: 'د بیا ترلاسه کولو کوډونه',
    NoCode: 'هیڅ د بیا ترلاسه کولو کوډ موجود نه دی.',
    SharedKey: 'د Google Auth مشترک کلید',
    Enable2fa: '2FA فعال کړئ',
    EnableTwoFactor: 'دوه مرحلې تایید فعال کړئ',
    note: 'د خپل Google Authenticator QR کوډ د جوړولو لپاره، لاندې تڼۍ باندې کلیک وکړئ.',
    Disable2fa: '2FA غیر فعال کړئ',
  },
  Enable2fa: `2-د فکتور تصدیق`,
  CRUD: {
    CreateTrade: 'سوداګري جوړه کړئ',
    WithdraFromAccount: 'له حساب څخه نغدي پیسې وباسئ',
    DepositToAccount: 'حساب ته نغدي پیسې جمع کړئ',
    DepositToUser: 'نغدي پیسې انتقال کړئ',
    CreateLoan: 'پور جوړ کړئ',
    TakeOrGiveLoan: 'پور واخلئ/ورکوئ',
    PayTakenLoan: 'پور ورکړئ',
    TradeEntry: 'سوداګرۍ داخل کړئ',
    DepositCash: 'نغدي پیسې جمع کړئ',
    WithDrawCash: 'نغدي پیسې وباسئ',
    TakePaidLoan: 'پور ترلاسه کړئ',
    Create: `جوړول`,
    Delete: `ړنکول`,
    List: `لیسټ`,
    Update: `تغیرول`,
    back: 'بیرته راګرزیدل',
    DeleteTitle: `آیا تاسو غواړی دا ریکارد ړنګ کړی`,
    Remarks: ` نظر`,
    Detail: `جزئیات`,
    Cancle: 'ګرځیدل',
    BackToList: 'لیست ته ورګرځیدل',
    Reset: 'بیا تنظیمول',
    Save: 'ذخیره کول',
    Send: 'قید صادره او لیږل',
    Sadera: 'قید صادره',
    Receive: 'وارده نیول',
    Remove: 'له منځه وړل',
    AddAttchment: 'نوی ضمیمه اضافه کړی',
    Edit: 'تغیرول',
    PasswordReset: 'د پټ نوم بیارغول',
    AddNew: 'نوی اضافه کړۍ',
    CCDep: 'ریاستونو ته کاپی',
    Add: 'اضافه کړۍ',
    //
    SelectOption: 'آپشن وټاکۍ',
    Yes: 'هو',
    No: 'نه',
    //
    SearchButton: 'لټون',
    SearchPlaceholder: 'لټون...',
    DeleteAttachment: 'آیا تاسو غواړۍ دا ضمیمه له منڅه یوسۍ؟',
    View: 'د سند کتل',
    TaskMembers: 'د عمل د اجرا غړي',
    MarkAsRead: 'لوستل شوی',
    MarkAsUnread: 'ندی لوستل شوی',
    Close: 'بندول',
  },
  docs: {
    hi: `سب سیبیبل سیبیبل یسبللام`,
    description: `توضیحات`,
    documentation: `اسناد`,
  },

  MainAsset: {
    AddTitle: 'حساب اضافه کړئ',
    UpdateTitle: 'حساب تازه کړئ',
    MainAssetList: 'د حسابونو لیست',
    New: 'نوی حساب',
    Update: 'حسابونه تازه کړئ',
    AssetType: 'د حساب ډول',
    Owneruser: 'مالک کاروونکی',
    DepositDate: 'د امانت نېټه',
    BalanceAmount: 'د حساب بیلانس',
    Description: 'توضیحات',
    MainAsset: 'حساب',
  },
  AssetType: {
    AssetTypeList: 'د حساب ډولونو لیست',
    Placeholder: 'دلته د حساب ډول وپلټئ...',
    CreateAssetType: 'د حساب ډول جوړ کړئ',
    AssetType: 'د حساب ډول سمول',
    New: 'نوی حساب ډول',
    Update: 'د حساب ډول تازه کړئ',
    Title: 'د حساب ډول',
    AddTitle: 'د حساب ډول اضافه کړئ',
    UpdateTitle: 'د حساب ډول تازه کړئ',
  },
  Branch: {
    Branch: 'څانګه',
    ParentName: 'د والد نوم',
    ModifiedOn: 'تازه شوی په',
    BranchList: 'د څانګو لیست',
    Placeholder: 'دلته څانګه وپلټئ',
    Address: 'د څانګې پته',
    Parent: 'د څانګې والد',
    CreateBranch: 'څانګه جوړ کړئ',
    EditBranch: 'څانګه سم کړئ',
    New: 'نوې څانګه',
    Update: 'څانګه تازه کړئ',
    AddTitle: 'څانګه اضافه کړئ',
    UpdateTitle: 'څانګه تازه کړئ',
  },
  PaymentType: {
    PaymentType: 'د پرداخت ډول',
    ParentName: 'د والد نوم',
    ModifiedOn: 'تازه شوی په',
    PaymentTypeList: 'د پرداخت ډول لیست',
    Placeholder: 'دلته د پرداخت ډول وپلټئ....',
    Address: 'د پرداخت ډول پته',
    Parent: 'د پرداخت ډول والد',
    CreatePaymentType: 'د پرداخت ډول جوړ کړئ',
    EditPaymentType: 'د پرداخت ډول سم کړئ',
    New: 'نوی د پرداخت ډول',
    Update: 'د پرداخت ډول تازه کړئ',
    AddTitle: 'د پرداخت ډول اضافه کړئ',
    UpdateTitle: 'د پرداخت ډول تازه کړئ',
  },
  Partners: {
    Partners: 'شریکان',
    ParentName: 'د والد نوم',
    ModifiedOn: 'تازه شوی په',
    PartnersList: 'د شریکانو لیست',
    Placeholder: 'دلته شریکان وپلټئ....',
    Address: 'د شریکانو پته',
    Parent: 'د شریکانو والد',
    CreatePartners: 'شریکان جوړ کړئ',
    EditPartners: 'شریکان سم کړئ',
    New: 'نوي شریکان',
    Update: 'شریکان تازه کړئ',
    AddTitle: 'شریکان اضافه کړئ',
    UpdateTitle: 'شریکان تازه کړئ',
  },
  CurrencyType: {
    CurrencyType: 'د اسعارو ډول',
    ParentName: 'د والد نوم',
    ModifiedOn: 'تازه شوی په',
    CurrencyTypeList: 'د اسعارو ډول لیست',
    Placeholder: 'دلته د اسعارو ډول وپلټئ....',
    Address: 'د اسعارو ډول پته',
    Parent: 'د اسعارو ډول والد',
    CreateCurrencyType: 'د اسعارو ډول جوړ کړئ',
    EditCurrencyType: 'د اسعارو ډول سم کړئ',
    New: 'نوی د اسعارو ډول',
    Update: 'د اسعارو ډول تازه کړئ',
    AddTitle: 'د اسعارو ډول اضافه کړئ',
    UpdateTitle: 'د اسعارو ډول تازه کړئ',
  },
  LoanType: {
    LoanType: 'د پور ډول',
    Title: 'د پور ډول',
    LoanTypeList: 'د پور ډول لیست',
    Placeholder: 'دلته د پور ډول وپلټئ',
    CreateLoanType: 'د پور ډول جوړ کړئ',
    EditLoanType: 'د پور ډول سم کړئ',
    New: 'نوی د پور ډول',
    Update: 'د پور ډول تازه کړئ',
    AddTitle: 'د پور ډول اضافه کړئ',
    UpdateTitle: 'د پور ډول تازه کړئ',
  },
  Partner: {
    Partner: 'شریک',
    PartnerPhone: 'د شریک تلیفون',
  },
  Department: {
    Dashboard: 'ډ شبورډ',
  },
  TradeTracking: {
    TradeTracking: 'د سوداګرۍ تاریخ لیست',
    Title: 'د سوداګرۍ تاریخ',
    New: 'نوی سوداګري',
    Update: 'سوداګري تازه کړئ',
    EnterTrade: 'سوداګري داخل کړئ',
    TradeAmount: 'د سوداګرۍ اندازه',
    ProfitAmount: 'د ګټې اندازه',
    LossAmount: 'د زیان اندازه',
    CreateTradeTracking: 'سوداګري جوړه کړئ',
    Placeholder: 'سوداګرۍ وپلټئ',
  },
  ExpenseTracking: {
    ExpenseTracking: 'د مصرف تاریخ لیست',
    Title: 'د مصرف تاریخ',
    New: 'نوی مصرف',
    Create: 'مصرف جوړ کړئ',
    Edit: 'مصرف سم کړئ',
    Update: 'مصرف تازه کړئ',
    EnterExpense: 'مصارف داخل کړئ',
    ExpenseAmount: 'د مصرف اندازه',
    ProfitAmount: 'د ګټې اندازه',
    LossAmount: 'د زیان اندازه',
    CreateExpenseTracking: 'مصرف جوړ کړئ',
    Placeholder: 'مصارف وپلټئ',
  },
  WithdrawalTracking: {
    WithdrawalTracking: 'د برداشت تاریخ لیست',
    Title: 'د برداشت تاریخ',
    New: 'نوی برداشت',
    Create: 'برداشت جوړ کړئ',
    Edit: 'برداشت سم کړئ',
    CreateDeposit: 'امانت جوړ کړئ',
    EditDeposit: 'امانت تازه کړئ',
    DepositToAccount: 'حساب ته امانت',
    Update: 'برداشت تازه کړئ',
    EnterWithdrawal: 'برداشتونه داخل کړئ',
    WithdrawalAmount: 'د برداشت اندازه',
    ProfitAmount: 'د ګټې اندازه',
    LossAmount: 'د زیان اندازه',
    CreateWithdrawalTracking: 'برداشت جوړ کړئ',
    Placeholder: 'برداشتونه وپلټئ',
  },
  ExpenseType: {
    ExpenseType: 'د مصرف ډول',
    ExpenseTypeList: 'د مصرف ډولونو لیست',
    Placeholder: 'دلته د مصرف ډول وپلټئ...',
    CreateExpenseType: 'د مصرف ډول جوړ کړئ',
    EditExpenseType: 'د مصرف ډول سم کړئ',
    New: 'نوی د مصرف ډول',
    Update: 'د مصرف ډول تازه کړئ',
  },
  npxChartDashboard: {
    TotalUsers: 'ټول کاروونکي',
    TotalBracnhes: 'ټولې څانګې',
  },
  //========================== Menu Translation ========================
  AssetManagementMenu: 'د حساب مدیریت',
  LoanTrackingMenu: 'د پور مدیریت',
  TradeTrackingMenu: 'د سوداګرۍ مدیریت',
  ExpenseTrackingMenu: 'د مصرف مدیریت',
  WithdrawalTrackingMenu: 'د برداشت مدیریت',
  lookuptable: 'د سیسټم جوړښت',
  BranchMenu: 'څانګه',
  ExpenseTypeMenu: 'د مصرف ډول',
  AssetTypeMenu: 'د حساب ډول',
  LoanTypeMenu: 'د پور ډول',
  PaymentTypeMenu: 'د پیسې ډول',
  CurrencyTypeMenu: 'د اسعارو ډول',
  PartnersMenu: 'شریکان',
  // ========================= End Menu Translation =====================
  LoanTracking: {
    AddTitle: 'پور جوړ کړئ',
    UpdateTitle: 'پور تازه کړئ',
    CreateLoanTracking: 'نوی پور جوړ کړئ',
    EditLoanTracking: 'پور سم کړئ',
    LoanTrackingList: 'د پورونو لیست',
    New: 'نوی پور',
    Update: 'پور تازه کړئ',
    LoanAmount: 'د پور اندازه',
    PaidAmount: 'ادائه شوې اندازه',
    RemainAmount: 'پاتې اندازه',
    Placeholder: 'د پورونو ثبتونه وپلټئ',
    GiveLoan: 'پور ورکړئ',
    TakeLoan: 'پور واخلئ',
  },
  //Login Page
  login: {
    SignInToMinistry: 'د مدیریت سیسټم (NPFX) ته ننوتل.',
    WelcomeBack: 'ښه راغلاست',
    UserName: 'کارن نوم',
    Password: 'پټ نوم',
    RememberMe: 'ما په یاد ولره',
    Login: 'دننه کیدل',
    ForgotPassword: 'پټ نوم مو هیر کړی؟',
    SendEmail:
      'مهرباني وکړۍ د خپل حساب سره تړلی بریښنالیک دننه کړۍ او موږ به تاسو ته یو کوډ درولیږو ترڅو خپل پټ نوم بیا تنظیم کړۍ',
    EmailMustBe: 'بریښنالیک مو باید سم وي',
    EmailIsRequired: 'بریښنالیک اړین دی',
    EmailAddress: 'بریښنالیک',
    SendRequest: 'درخواست ولیږۍ',
    RequestSent: 'غوښتنه په بریالیتوب سره لیږل شوې!',
    WeHaveSent: 'موږ یو 4 عددي تاییدي کوډ ستاسو بریښنالیک ته لیږلی دی.',
    PleaseEnter: 'مهرباني وکړۍ د خپل بریښنالیک تصدیق کولو لپاره لاندې بکس کې کوډ دننه کړۍ.',
    VerifyCode: 'کوډ تایید کړۍ',
    DontHaveACode: 'کوډ مو ترلاسه کړی نه دی؟',
    ResendCode: 'د کوډ بیا لیږل',
    ConfirmNewPassword: 'نوی پټ نوم تایید کړۍ',
    ChangePassword: 'پټ نوم تغیرول',
    PasswordMustBe: 'پټ توری باید لږ تر لږه 6 توري وي',
    PasswordIsRequired: 'پټ توری اړین دی',
    ConfirmPassword: 'د پټ توري تاییدي اړینه ده',
    PasswordMatch: 'پټ توری باید یو شان اوسي',
    EmailNotExist: 'بریښنالیک شتون نه لري، مهرباني وکړۍ خپل بریښنالیک راجسټر کړۍ!',
    InvalidCode: 'کوډ سم نه دی، بیا هځه وکړۍ!',
  },

  Pagination: {
    RowsPerPage: 'د ریکارډونو شمیر په یوه صفحه کی',
    Dense: 'لنډ',
    Of: 'له',
  },
  Validation: {
    EnglishName: 'نوم په انګلیسي کې اړین دی',
    DariName: 'نوم په دري کې اړین دی',
    PashtoName: 'نوم په پښتو کې اړین دی',
    EnglishSurName: 'تخلص په انګلیسي کې اړین دی',
    PashtoSurName: 'تخلص په پښتو کې اړین دی',
    Locationn: 'موقعیت اړین دی',
    Contact: 'شماره اړینه ده',
    Type: 'ډول اړین دی',
    Code: 'کوډ اړین دی',
    Language: 'ژبه اړینه ده',
    Remark: 'مهربانی وکړی نظر مو شریک کړی',
    Name: 'نوم اړین دی.',
    ContactNumber1: 'د تلفن ۱ شمیره اړینه ده',
    ContactNumber2: 'د تلفن ۲ شمیره اړینه ده',
    Email: 'ایمیل اړین دی.',
    Department: 'ریاست اړین دی',
    User: 'کاروونکی اړین دی',
    Comments: 'نظر اړین دی',
    Description: 'توضیحات اړین دی',
    FatherEnglishNameIsRequired: 'د پلار نوم په انګلیسي کې اړین دی',
    FatherDariNameIsRequired: 'د پلار نوم په پښتو کې اړین دی',
    GrandFatherEnglish: 'د نیکه نوم په انګلیسي کې اړین دی',
    GrandFatherPashto: 'د نیکه نوم په پښتو کې اړین دی',
    GenderIsRequired: 'مهرباني وکړۍ جنسیت وټاکۍ',
    PositionIsRequired: 'بست اړین دی',
    DegreeIsRequired: 'مهرباني وکړۍ د کارکوونکي د زده کړو درجه وټاکۍ',
    EmployeeHealthStatus: 'مهرباني وکړۍ د کارکوونکي روغتیایي حالت وټاکۍ، اړین دی',
    CountryNameIsRequired: 'مهرباني وکړۍ د هیواد نوم وټاکۍ، اړین دی',
    //
    DateOfBirth: 'د زیږیدنې نینه اړینه ده',
    TemporaryAddress: 'اوسنی آدرس اړین دی',
    PermanentAddress: 'دایمي آدرس اړین دی',
    AttendanceId: 'د حاضري آی ډي اړینه ده',
    CNIC: 'د تذکرې شمیره اړینه ده',
    Gender: 'مهرباني وکړۍ جنست وټاکۍ، جنسیت اړین دی',
    JoinDate: 'د مقررۍ نیټه اړینه ده',
    PersonalEmail: 'شخصي بریښنالیک اړین دی',
    OfficialEmail: 'رسمي بریښنالیک اړین دی',
    Province: 'مهرباني وکړۍ ولایت وټاکۍ، ولایت اړین دی',
    District: 'مهرباني وکړۍ ولسوالۍ وټاکۍ، ولسوالۍ اړینه ده',
    BloodGroup: 'مهرباني وکړۍ د وینې ګروپ وټاکۍ، اړین دی',
    LeaveDate: 'د وتلو نیټه اړینه ده',
    LeaveRemarks: 'مهرباني وکړۍ د وتلو جزئیات ولیکۍ',
    isCurrent: 'مهرباي وکړۍ وټاکۍ چې آیا اوس کارمند دی؟',
    ProfilePhoto: 'د پروفایل تصویر اړین دی',
    //ContractDetailsType
    contractType: 'مهرباني وکړۍ د قرارداد ډول وټاکۍ، اړین دی',
    positionTitle: 'مهرباني وکړۍ د دندې عنوان وټاکۍ، اړین دی',
    jobPosition: 'مهرباني وکړۍ د بست عنوان وټاکۍ، اړین دی',
    grade: 'مهرباني وکړۍ بست وټاکۍ، اړین دی',
    gradeStep: 'مهرباني وکړۍ د بست قدم وټاکۍ، اړین دی',
    department: 'مهرباني وکړۍ ریاست وټاکۍ، اړین دی',
    //EducationLevelDetails
    educationLevel: 'مهرباني وکړۍ د تحصیل درجه وټاکۍ، اړینه دی',
    educationTitle: 'مهرباني وکړۍ د تحصیل رشته وټاکۍ، اړینه دی',
    englishUniName: 'د تحصیلي مرجع نوم په انګلیسي کې اړین دی',
    pashtoUniName: 'د تحصیلي مرجع نوم په پښتو کې اړین دی',
    dariUniName: 'د تحصیلي مرجع نوم په دري کې اړین دی',
    comment: 'مهرباني وکړۍ خپل نظر وړاندې کړۍ، اړین دی',
    DoubleClick: 'دوه ځلي کلیک وکړۍ ترڅو د ',
    RequestDoubleClick: 'دوه ځلي کلیک وکړۍ ترڅو د ',
    DocumentDetails: 'سند جزئیات وښودل شي',
    RequestDetails: 'غوښتنې جزئیات وښودل شي',
    ClickHere: 'دلته کلیک وکړۍ ترڅو جزئیات روښانه شي',
    //
    FromDate: 'له کومې نیټې څخه وټاکۍ',
    ToDate: 'تر کومې نیټې وټاکۍ',
    //Grade
    ContractType: 'مهرباني وکړۍ د قرارداد ډول وټاکۍ، اړین دی',
    ActionRequestDoubleClick: 'دوه ځلي کلیک وکړۍ ترڅو د ',
    ActionRequestDetails: ' جزئیات وښودل شي',
    DistrictCode: 'د ولسوالۍ کوډ اړین دی',
    setDefault: 'مهرباني وکړۍ وټاکۍ چې آیا لیکل شوی کال روان کال دی که نه',
    EmployeeIsRequired: 'کارکوونکی اړین دی',
    ReportToDepartment: 'مهرباني وکړۍ کوم ریاست ته چې راپور ورکول کیږي، هفه وټاکۍ',
    Directorate: 'مهرباني وکړۍ ریاست وټاکۍ، اړین دی',
    TitleIsRequired: 'عنوان اړین دی',
    // NPFX
    ExpenseType: 'Expense Type is Required',
    Account: 'Account is Required',
    Branch: 'Branch is Required',
    Date: 'Date is Required',
    Amount: 'Amount is Required',
    LoanAmount: 'Loan Amount is Required',
    LoanType: 'Loan Type is Required',
    CurrencyType: 'Currency Type is Required',
    AccountType: 'Account Type is Required',
    OwnerUser: 'Owner User is Required',
    DepositDate: 'Deposit Date is Required',
    BalanceAmount: 'Balance Amount is Required',
    DepositAmmount: 'Deposit Ammount is Required',
    ToUser: 'To User is Required',
    TradeAmount: 'Trade Amount is Required',
    WithdrawalAmount: 'Withdrawal Amount is Required',
    // Property Validation
    PropertyIsRequired: 'ملکیت اړین دی',
    ConditionId: 'شرایط اړین دی',
    Category: 'کټګوري اړینه ده',
    Price: 'قیمت اړین دی',
    AmountPaid: 'ورکړل شوی مقدار اړین دی',
    PaymentDate: 'د پیسو نیټه اړینه ده',
  },
  // Fields names which repeadedly uses in tables
  GeneralFields: {
    Id: 'آی ډي',
    Name: 'نوم',
    EnglishName: 'انګلیسي نوم',
    PashtoName: 'پښتو نوم',
    DariName: 'دري نوم',
    Code: 'کوډ',
    Action: 'عملیات',
    StartDate: 'د پیل نیټه',
    EndDate: 'د پای نیټه',
    Remarks: 'جزئیات',
    Description: 'توضیحات',
    Date: 'نیټه',
    SelectAll: 'تول',
  },

  Tostar: {
    Success: '!په بریالیتوب سره ړنګ شو',
    DeleteSuccess: '!په بریالیتوب سره ړنګ شو',
    DeleteFailed: '!ړنګ نشو',
    CreateSuccess: ' !په بریالیتوب سره جوړ شو',
    UpdateSuccess: ' !په بریالیتوب سره تغییر شو',
    RejectSuccess: ' !په بریالیتوب سره رد شو',
    RejectFaild: ' !رد نشو',
    UserActivated: ' !کاروونکی فعال شو',
    UserDeactivated: ' !کاروونکی غیر فعال شو',
    DataIsAlreadyExist: 'ډیټا له مخکې نه شتون لري',
    LoginSuccess: '!په بریالیتوب سره سیسټم ته داخل شوۍ',
    TaskReAsign: 'دنده په بریالیتوب سره وسپارل شوه',
    ApproveSuccess: 'په بریالیتوب سره تایید شو',
    ForwardSuccess: 'په بریالیتوب سره ولیږل شو',
    CardAssignedSuccess: 'کارټ په بریالیتوب سره وټاکل شو',
    NewDocumentReceived: 'نوی سند ترلاسه شو',
    DocumentRejected: 'سند رد شو',
    NewInternalDocumentReceived: 'نوی داخلي سند ترلاسه شو',
    NewExternalDocumentReceived: 'نوی خارجي سند ترلاسه شو',
    DocumentReceived: 'سند ترلاسه شو',
    NewTicket: 'نوی فورم',
    TicketCompleted: 'فورم تکمیل شو',
    TicketRejected: 'فورم رد شو',
    TicketReceived: 'فورم ترلاسه شو',
    NewvisitorRequest: 'د مراجعه کوونکي نوی پیشنهاد',
    VisitorRequestApproved: 'د مراجعه کوونکي پیشنهاد تایید شو',
    VisitorRequestRejected: 'د مراجعه کوونکي پیشنهاد رد شو',
    MarkedAsRead: 'د لوستل شوي په توګه نښه شو',
    MarkedAsUnRead: 'د نه لوستل شوي په توګه نښه شو!',
    ChangePassword: 'پټ توری په بریالیتوب سره بدل شو!',
    ReceivedSuccess: 'سند په بریالیتوب سره وارده ونیول شو',
  },

  ProcessStatus: {
    Title: 'د پروسو حالت: لیسټ',
    CreateTitle: 'د پروسو حالت: نوی اضافه کول',
    ProcessStatusList: 'د پروسو د حالت لیست',
    ProcessStatus: 'د پروسې حالت',
    Placeholder: 'د پروسو حالت ولټوی...',
    NewProcessStatus: 'د پروسی نوی حالت اضافه کړی ',
    UpdateProcessStatus: ' د پروسی حالت تغییر کړی ',
    CreateProcessStatus: 'د پروسی حالت جوړ کړی',
  },
  APPlication: {
    App: 'سیستم',
    AppList: 'د سیستمونو لیست',
    Placeholder: 'سیستم ولټوی...',
    Title: 'عنوان',
    Id: 'آی دی',
    Action: 'عملیات',
    Abbrevation: 'مخفف',
    IconClass: 'آیکن کلاس ',
    DefaultRoute: 'ډیفالټ روټ',
    Area: 'ساحه',
    Description: 'تشریحات',
    NewApp: ' نوی سیستم اضافه کړی ',
    UpdateApp: 'سیست تغییر کړی',
    CreateApp: 'سیستم جوړ کړی',
  },

  //Notifications
  Notifications: {
    Notifications: 'خبرتیاوې',
    New: 'نوي',
    BeforeThat: 'مخکني',
    ViewAll: 'د ټولو کتل',
    From: 'له طرفه د',
    ViewMore: 'د نورو کتل',
  },

  //HR Employee
  Employee: {
    Title: 'کارکوونکی: د کارکوونکو لیسټ',
    EmployeeList: 'د کارکوونکو لیسټ',
    Id: 'شمیره',
    Name: 'نوم',
    FatherName: 'د پلار نوم',
    EmployeeType: 'د کارکوونکي ډول',
    Department: 'ریاست',
    Position: 'بست',
    Email: 'بریښنالیک',
    Action: 'عملیات',
    PhoneNumber: 'د اړیکې شمیره',
    Placeholder: 'کارکوونکی د نوم، ریاست یا د اړیکې شمیرې په اساس ولټوۍ ...',
    CreateTitle: 'کارکوونکی: د نوي کارکوونکي اضافه کول',
    CreateEmployee: 'یو نوی کارکوونکی اضافه کړۍ',
    UpdateEmployee: 'کارکوونکی تغیر کړۍ',
    NewEmployee: 'نوی کارکوونکی',
    UpdateEmp: 'د کارکوونکي تغیرول',
    EnglishName: 'نوم په انګلیسي',
    DariName: 'پښتو نوم (د بست عنوان)',
    PashtoName: 'نوم په پښتو',
    FatherEnglishName: 'د پلار نوم په انګلیسي',
    FatherDariName: 'د پلار نوم په پښتو',
    Gender: 'جنسیت',
    Male: 'ذکور',
    Female: 'اناث',
    Official: 'رسمي',
    Contractual: 'قراردادي',
    Intermittently: 'باالمقطع',
    JoinDate: 'د مقرري نیټه',
    EmployeeDegree: 'د زده کړو درجه',
    BloodGroup: 'د وینې ګروپ',
    AttendanceId: 'د حاضري آی ډي',
    Details: 'جزئیات',
    CreateUser: 'اکونټ اضافه کړۍ',
    ContractDetails: 'د قرارداد جزئیات',
    CardDetails: 'د کارټ جزئیات',
    EducationalLevelDetails: 'د تحصیل درجه',
    EnglishBioData: 'بیوګرافي معلومات په انګلیسي',
    PashtoDariBioData: 'بیوګرافي معلومات په پښتو/دري',
    ProfilePhoto: 'د پروفایل تصویر',
    CropImage: 'د تصویر سمول',
    TazkiraType: 'د تذکرې ډول',
    ETazkira: 'الکترونیکي تذکره',
    PaperBaseTazkira: 'کاغذي تذکره',
    JoldNo: 'د ټوک ګڼه',
    PageNo: 'د پاڼې ګڼه',
    RegNo: 'د ثبت ګڼه',
    //
    englishSurName: 'تخلص په انګلیسي',
    pashtoSurName: 'تخلص په پښتو',
    englishGrandFatherName: 'د نیکه نوم په انګلیسي',
    pashtoGrandFatherName: 'د نیکه نوم په پښتو',
    dateOfBirth: 'د زیږیدنې نیټه',
    temporaryAddress: 'اوسنی آدرس',
    permanentAddress: 'دایمي آدرس',
    attendanceId: 'د حاضري آی ډي',
    cnic: 'د تذکرې شمیره',
    leaveDate: 'د تلو نیټه',
    leaveRemark: 'د تلو جزئیات',
    personalEmail: 'شخصي بریښنالیک',
    officialEmail: 'رسمي بریښنالیک',
    rfidNumber: 'RFID شمیره',
    isCurrent: 'آیا اوس کارمند دی',
    IsCurrentContract: 'د قرارداد حالت',
    emergencyPhoneNumber: 'بیړنۍ اړیکې شمیره',
    registrationNumber: 'د راجسټر شمیره',
    Province: 'ولایت',
    District: 'ولسوالۍ',
    Employee: 'کارکوونکی',
    DoubleClick: 'دوه ځلي کلیک وکړۍ ترڅو د  ',
    EmployeeDetail: 'جزئیات وښودل شي ',
    //EmployeeDetails
    DetailsTitle: 'کارکوونکی: د کارکوونکي جزئیات',
    EmployeeDetails: 'د کارکوونکي جزئیات',
    EmployeeHealthStatus: 'د کارکوونکي روغتیایي حالت',
    EmployeeGeneralInfo: 'د کارکوونکي عمومي معلومات',
    //Employee Report
    EmployeeReportTitle: 'کارکوونکی: د کارکوونکو راپور',
    EmployeeReport: 'د کارکوونکو راپور',
    HasAccount: 'کارن حساب لري',
    hasAccount: 'لري',
    NoAccount: 'نه لري',
    FullName: 'بشپړ نوم',
    Account: 'کارن حساب',
  },

  //ContractDetails
  ContractDetails: {
    Title: 'د قرارداد جزئیات: لیسټ',
    ContractDetailsList: 'د قرارداد د جزئیاتو لیسټ',
    Placeholder: 'د قرارداد جزئیات ولټوۍ...',
    AddTitle: 'د قرارداد جزئیات: اضافه کول',
    CreateContractDetails: 'د قرارداد جزئیات اضافه کړۍ',
    UpdateTitle: 'د قرارداد جزئیات: تغیرول',
    EditContractDetails: 'د قرارداد جزئیات تغیر کړۍ',
    New: 'د قرارداد د جزئیاتو اضافه کول',
    Update: 'د قرارداد د جزئیاتو تغیرول',
    Active: 'برحال',
    ContractWarning:
      'خبرداری: د ذخیره کولو تڼۍ غیر فعاله ده ځکه چې د کارکوونکي اوسنی قرارداد پای ته نه دی رسیدلی، مهرباني وکړۍ د کارکوونکي اوسنی قرارداد تازه کړۍ.',
    Branch: ' څانګه',
    currencyType: ' د اسعارو ډول',
    salaryAmount: 'د معاش اندازه',
    positionTitle: ' د دندې سرلیک',
  },
  //Employee Degree
  EmployeeDegree: {
    Title: 'د کارکوونکو د زده کړو درجه: د درجو لیسټ',
    EmployeeDegreeList: 'د کارکوونکو د زده کړو د درجو لیسټ',
    Placeholder: 'د کارکوونکي د زده کړو درجه ولټوۍ ...',
    CreateTitle: 'د کارکوونکو د زده کړو درجه: نوی درجه',
    CreateEmployeeDegree: 'د کارکوونکي نوی د زده کړې درجه اضافه کړۍ',
    EditEmployeeDegree: 'د کارکوونکي د زده کړې درجه تغیر کړۍ',
    NewEmployeeDegree: 'د کارکوونکي نوی د زده کړې درجه',
    UpdateEmployeeDegree: 'د کارکوونکي د زده کړې درجې تغیرول',
  },
  //Employee Position
  EmployeePosition: {
    Title: 'د کارکوونکو بست: د کارکوونکو د بستونو لیسټ',
    EmployeePositionList: 'د کارکوونکو د بستونو لیسټ',
    Placeholder: 'د کارکوونکو بستونه ولټوۍ ...',
    CreateTitle: 'د کارکوونکو بست: نوي بست اضافه کول',
    CreateEmployeePosition: 'د کارکوونکو نوی بست اضافه کړۍ',
    EditEmployeePosition: 'د کارکوونکو بست تغیر کړۍ',
    NewEmployeePosition: 'د کارکوونکو نوی بست',
    UpdateEmployeePosition: 'د کارکوونکو بست تغیرول',
  },

  //User Management
  User: {
    user: 'کاروونکې',
    newUser: 'نوی کاروونکې',
    userList: 'د کاروونکو لیسټ',
    userName: 'د کاروونکي نوم',
    fullUserName: 'د کاروونکي مکمل نوم',
    email: 'بریښنا لیک',
    isActive: 'فعال دې',
    Active: 'فعال دی',
    Branch: 'نماینده گی',
    unActive: 'غیر فعال دی',
    Banned: 'غیر فعال دی',
    createUser: 'نوی کاروونکې اضافه کړۍ',
    editUser: 'د کاروونکي تغیرول',
    Placeholder: 'کاروونکې ولټوی ...',
    phoneNumber: 'د اړیکې شمیره',
    positionTitle: 'د بست نوم',
    Language: 'ژبه',
    userRoles: 'د کاروونکي رول',
    AllowedDepartments: 'اجازه ورکړل شوي ریاستونه',
    AllowedSecurityLevels: 'اجازه ورکړل شوی امنیتي کچه',
    AllowedDocumentType: 'اجازه ورکړل شوي سند ډولونه',
    Organization: 'اداره',
    CreateUser: 'کاروونکې اضافه کړۍ',
    UpdateChanges: 'کاروونکې تغیر کړۍ',
    Allowed: 'اجازه لري *.jpeg, *.jpg, *.png',
    MaxSize: 'لوړ سایز',
    UploadPhoto: 'تصویر اضافه کړۍ',
    UpdatePhoto: 'تصویر تغیر کړۍ',
    NoPositionAdded: 'هیڅ بست اضافه شوی نه دې',
    SecurityLevel: 'دامنیت کچه',
    UserDetails: 'د کاروونکي جزئیات',
    ResetPassword: 'د پټ نوم بیارغول',
    NewPassword: 'نوی پټ نوم',
    ChangePassword: 'د پټ نوم بدلول',
    CurrentPassword: 'اوسنی پټ نوم',
    ConfirmNewPassword: 'نوی پټ نوم تایید کړۍ',
    Title: 'کاروونکی: د کاروونکو لیسټ',
    active: 'فعال',
    Deactive: 'غیر فعال',
    AppName: 'د اپلیکیشن نوم',
    Description: 'توضیحات',
    EnglishName: 'انګلیسي نوم',
    Code: 'کوډ',
    PashtoName: 'پښتو نوم',
    DariName: 'دري نوم',
    About: 'اړ معلومات',
    Contact: 'اړیکې شمیره',
    GeneralInfo: 'عمومي معلومات',
    ProfileTitle: 'کاروونکی: پروفایل',
    Profile: 'د کاروونکي پروفایل',
    UserPosition: ' د کاروونکي بست نوم',
    Employee: 'کارکوونکی',
    Representator: 'استازی',
    userPassword: 'د کاروونکي پټ نوم',
    department: 'ریاست',
    isManager: 'آیا اجرائیه مدیر دی',
    Action: 'عمل',
    ActionOn: 'د عمل نیټه',
    IpAddress: 'آی پي آډرس',
    Message: 'پیغام',
    Server: 'سرور',
    BrowserName: 'د بروزر نوم',
    Os: 'عملیاتي سیسټم',
    userLogTitle: 'د کاروونکي تاریخچه: لیسټ',
    userLogList: ' د کاروونکو د تاریخچې لیسټ',
    ActiveUsers: 'فعال کاروونکی',
    Managers: 'اجرائیه مدیران',
    ToggleOrientation: 'د صفحې شکل تغیرول',
    UserReport: 'د کاروونکو راپور',
    Day: 'ورځ',
    // Validation
    PleaseEnterNewPassword: 'مهرباني وکړۍ نوی پټ نوم ورکړۍ',
    UserNameIsRequired: 'د کاروونکي نوم اړین دی',
    EmailIsRequired: 'د کاروونکي بریښنالیک اړین دی',
    NumberFormat: '+937xxxxxxxx د شمیرې فارمټ باید داسې وي',
    PhoneNumberIsRequired: 'د ټلیفون شمیره اړینه ده',
    PositionTitleIsRequired: 'د بست نوم اړین دی',
    LanguageIsRequired: 'ژبه اړینه ده',
    PleaseEnterRmarks: 'مهرباني وکړۍ د ړنګولو دلیل ولیکۍ',
    CurrentPassIsRequired: 'اوسنې پټ نوم اړین دی',
    AtLeastChar: 'پټ نوم باید لږترلږه 6 توري وي',
    NewPassIsRequired: 'نوی پټ نوم اړین دی',
    passMustMatch: 'پټ نومونه باید یو بل سره سمون ولري',
    PasswordIsRequired: 'پټ نوم اړین دی',
    NewPassRequiresAlpha: 'پټ نوم باید حروف ولري',
    NewPassRequiresNumber: 'پټ نوم باید عددونه ولري',
    NewPassRequiresSpecialChar: 'پټ نوم باید خاص حروف ولري',
  },
  //Account
  Account: {
    Home: 'اصلي صفحه',
    Profile: 'پروفایل',
    Logout: 'وتل',
  },
  Categorys: {
    Categorys: 'کتګورۍ',
    Title: 'کتګورۍ',
    CategorysList: 'د کتګورۍ ډول لیست',
    Placeholder: 'دلته کتګورۍ وپلټئ',
    CreateCategorys: 'کتګورۍ جوړول',
    EditCategorys: 'کتګورۍ سمول',
    New: 'نوې کتګورۍ',
    Update: 'کتګورۍ تازه کول',
    AddTitle: 'کتګورۍ اضافه کړئ',
    UpdateTitle: 'کتګورۍ تازه کړئ',
  },
  PropertyConditions: {
    PropertyConditions: 'د ملکیت حالت',
    Title: 'د ملکیت حالت',
    PropertyConditionsList: 'د ملکیت د حالتو ډول لیست',
    Placeholder: 'دلته د ملکیت حالت وپلټئ',
    CreatePropertyConditions: 'د ملکیت حالت جوړ کړئ',
    EditPropertyConditions: 'د ملکیت حالت سم کړئ',
    New: 'نوي د ملکیت حالت',
    Update: 'د ملکیت حالت تازه کړئ',
    AddTitle: 'د ملکیت حالت اضافه کړئ',
    UpdateTitle: 'د ملکیت حالت تازه کړئ',
    categorys: 'کتګورۍ',
    propertyConditions: 'د ملکیت حالت',
  },
  Property: {
    AddTitle: 'نوی ملکیت جوړول',
    PropertyList: 'د ملکیتونو لیست',
    Condition: 'حالت',
    UpdateTitle: 'د ملکیت تازه کول',
    DetailsTitle: 'د ملکیت تفصیلات',
    New: 'نوی ملکیت',
    Update: 'ملکیت تازه کول',
    Employee: 'د کارمند نوم',
    PropertyConditions: 'د ملکیت شرایط',
    Model: 'ماډل',
    PaymentDate: 'د پیسو نیټه',
    Payments: 'پیسي',
    Payment: 'پیسه',
    Price: 'قیمت',
    Assignments: 'وظیفې',
    Assignment: 'وظیفه',
    AmountPaid: 'ورکړل شوی مقدار',
    PayableAmount: 'ورکړلو وړ مقدار',
    RemainingBalance: 'باقي پاتې پیسې',
    MaintenanceHistory: 'د ساتنې تاریخچه',
    Placeholder: 'دلته پلټنه وکړئ ....',
  },
  //Role
  userRole: {
    Id: 'آی ډي',
    Application: 'آپلیکیشن',
    Name: 'نوم',
    Description: 'توضیحات',
    Permission: 'اجازه',
    TotalPermissions: 'ټولې اجازې',
    PermissionName: 'د اجازې نوم',
    ControllerName: ' کنټرولر نوم',
    Method: 'میتود',
    RoleList: 'د رولونو لیسټ',
    Role: 'رول',
    Placeholder: 'رول ولټوی ...',
    CreateNewRole: 'یو نوی رول جوړ کړۍ',
    EditRole: 'رول تغیر کړۍ',
    NewRole: 'نوی رول',
    RoleEdit: 'د رول تغیرول',
    CreateRole: 'رول جوړ کړۍ',
    UpdateRole: 'رول تغیر کړۍ',
    RoleDetails: 'د رول جزئیات',
    RoleName: 'د رول نوم',
    PermissionDetails: 'د اجازې توضیحات',
    //Validation
    NameIsRequired: 'نوم اړین دی',
    AppIsRequired: 'اپلیکیشن اړین دی',
    DescriptionIsRequired: 'توضیحات اړین دي',
  },
  //permission
  userPermission: {
    ApplicationName: 'د اپلیکیشن نوم',
    isActionCategoryMissing: 'د عمل کټګوري نشته ده',
    Controller: 'کنټرولر',
    ActionCategory: 'د عمل کټګوري',
    SaveAll: 'ټول ذخیره کړۍ',
    IsGlobal: 'ګلوبل دی',
    Id: 'آی دی',
    Name: 'نوم',
    PermissionController: 'کنټرولر',
    Action: 'عملیات',
    Method: 'میتود',
    Application: 'اپلیکیشن',
    PermissionList: 'د اجازو لیسټ',
    Placeholder: 'اجازه ولټوۍ ...',
  },
  HRDashboard: {
    HRDashboard: 'د بشري سرچینو ډشبورډ',
    TotalEmployeesHasUser: 'ټولټال کارکونکي چې کارن حساب لري',
    TotalActiveEmployeeByDepartments: 'ټولټال فعال کارکونکي د ډیپارټمنټونو په اساس',
  },

  Dashboard: {
    MISWorkDashboard: 'د MIS د کارونو ډشبورډ',
    ReceptionDashboard: 'د استقبالیه سیسټم ډشبورډ',
    ArchiveDashboard: ' د آرشیف ډشبورډ',
    InternalDocumentDashboard: 'د داخلی اسنادو ډشبورډ',
    ExternalDocumentDashboard: 'د خارجي اسنادو ډشبورډ',
    UserPerformanceDashboard: 'د کاروونکي د فعالیتونو ډشبورډ',
    ITSMSDashboard: 'د IT-SMS ډشبورډ',
    UserSummaryDashboard: 'د کارن لنډیز ډشبورډ',
  },

  ProcessName: {
    All: 'ټول',
    Completed: 'تکمیل شوي',
    InProcess: 'د اجرا په حال کې',
    Pending: 'د انتظار په حال کې',
    NotCompleted: 'نیمګړې',
    Approve: 'تصدیق',
    Reject: 'رد شوې',
    DocumentProcessEnd: 'د سند د پروسې پای',
    ReplytoReceivedDocument: 'د ترلاسه شوي سند څواب',
    ExecuteandMessageSending: 'اجرا  او پیغام استول',
    ProcessStart: 'پروسی پیل',
    Sent: 'استول شوی',
    Received: 'لاس ته شوي',
    DueGiven: 'قرض ورکړل شوی',
    DueTaken: 'قرض اخیستل شوی',
  },
  //Province
  Province: {
    Title: 'ولایت: د ولایتونو لیسټ',
    ProvinceList: 'د ولایتونو لیسټ',
    Province: 'ولایت',
    Code: 'د ولایت کوډ',
    Placeholder: 'ولایت دلته ولټوۍ ...',
    AddTitle: 'ولایت: د نوي ولایت اضافه کول',
    CreateNewProvince: 'نوی ولایت اضافه کړۍ',
    UpdateProvince: 'ولایت تغیر کړۍ',
  },

  //District
  District: {
    Title: 'حوزه: د حوزو لیسټ',
    DistrictList: 'د حوزو لیسټ',
    District: 'حوزه',
    Code: 'د حوزې کوډ',
    Placeholder: 'حوزه دلته ولټوۍ ...',
    AddTitle: 'حوزه: د نوی حوزې اضافه کول',
    CreateNewDistrict: 'یوه نوی حوزه اضافه کړۍ',
    UpdateDistrict: 'حوزه تغیر کړۍ',
    EnglishName: 'انګلیسي نوم',
    PashtoName: 'پښتو نوم',
    DariName: 'دري نوم',
    UpdateTitle: 'حوزه: د حوزې تغیرول',
  },
  //HR Module
  //Education Title
  EducationTitle: {
    Title: 'د تحصیل رشته: لیسټ',
    AddTitle: 'د تحصیل رشته: اضافه کول',
    UpdateTitle: 'د تحصیل رشته: تغیرول',
    educationTitleList: 'د تحصیلي رشتې لیسټ',
    educationTitle: 'د تحصیل رشته',
    dashboard: 'ډشبورډ',
    iD: 'ای ډی',
    engName: 'انګلیسی نوم',
    dariName: 'دري نوم',
    pashtoName: 'پښتو نوم',
    code: 'کوډ',
    action: 'عملیات',
    remarks: 'مهرباني وکړۍ خپل نظر ولیکۍ',
    searchPlaceHolder: 'د تحصیل رشته د انګلیسي نوم، پښتو نوم، دري نوم یا د کوډ په اساس ولټوۍ...',
    updateEducationTitle: 'تحصیلي رشته تغیر کړی',
    newEducationTitle: 'نوی تحصیلي رشته اضافه کړی',
    New: 'د تحصیلي رشتې اضافه کول',
    Update: 'د تحصیلي رشتې تغیرول',
  },
  EducationLevel: {
    Title: 'د تحصیل درجه: لیسټ',
    AddTitle: 'د تحصیل درجه: اضافه کول',
    UpdateTitle: 'د تحصیل درجه: تغیرول',
    educationLevelList: 'د تحصیلی درجې لیست',
    educationLevel: 'د تحصیل درجه',
    dashboard: 'ډشبورډ',
    iD: 'ای ډی',
    engName: 'انګلیسی نوم',
    dariName: 'دری نوم',
    pashtoName: 'پشتو نوم',
    code: 'کوډ',
    action: 'عملیات',
    remarks: 'مهربانی وکړی خپل نظر ولیکی',
    searchPlaceHolder: 'د تحصیل درجه د انګلیسي نوم، پښتو نوم، دري نوم یا د کوډ په اساس ولټوی',
    updateEducationLevel: 'تحصیلی درجه تغیر کړی',
    newEducationLevel: 'نوی تحصیلی درجه اضافه کړی',
    New: 'د تحصیلي درجې اضافه کول',
    Update: 'د تحصیلې درجې تغیرول',
    Country: 'هیواد',
  },

  //EducationLevelDetails
  EducationLevelDetails: {
    Title: 'د تحصیل درجې جزئیات: لیسټ',
    CreateTitle: 'د تحصیل درجې جزئیات: اضافه کول',
    UpdateTitle: 'د تحصیل درجې جزئیات: تغیرول',
    EducationLevelDetailsList: 'د تحصیل درجې د جزئیاتو لیسټ',
    Create: 'د تحصیل درجې نوي جزئیات اضافه کړۍ',
    Edit: 'د تحصیل درجې جزئیات تغیر کړۍ',
    EmployeeName: 'د کارکوونکي نوم',
    UniversityName: 'د تحصیلي مرجع نوم',
    New: 'د تحصیل درجې د جزئیاتو اضافه کول',
    Update: 'د تحصیل درجې د جزئیاتو تغیرول',
    Placeholder: 'د تحصیل درجې جزئیات دلته ولټوۍ...',
    EnglishUniName: 'د تحصیلي مرجع نوم په انګلیسي',
    PashtoUniName: 'د تحصیلي مرجع نوم په پښتو',
    DariUniName: 'د تحصیلي مرجع نوم په دري',
  },

  //ContractType
  ContractType: {
    Title: 'د قرارداد ډول: لیسټ',
    ContractTypeList: 'د قرارداد ډول لیسټ',
    Placeholder: 'د قرارداد ډولونه د انګلیسي نوم، پښتو نوم، دري نوم یا د کوډ په اساس ولټوۍ...',
    AddTitle: 'د قرارداد ډول: اضافه کول',
    CreateContractType: 'یو نوی د قرارداد ډول اضافه کړۍ',
    UpdateTitle: 'د قرارداد ډول: تغیرول',
    EditContractType: 'د قرارداد ډول تغیر کړۍ',
    New: 'د قرارداد ډول اضافه کول',
    Update: 'د قرارداد ډول تغیرول',
    ContractType: 'د قرارداد ډول',
    ContractStatus: 'د قرارداد حالت',
    Current: 'اوسنـی',
    Ended: 'ختم شوی',
    EmployeeIsPermanent: 'کارکوونکی دایمي دی',
  },

  //Grade
  Grade: {
    Title: 'بست: د بستونو لیسټ',
    GradeList: 'د بستونو لیسټ',
    Placeholder: 'بستونه د انګلیسي نوم، پښتو نوم، دري نوم یا د کوډ په اساس ولټوۍ...',
    AddTitle: 'بست: د بست اضافه کول',
    CreateGrade: 'یو نوی بست اضافه کړۍ',
    UpdateTitle: 'بست: د بست تغیرول',
    EditGrade: 'بست تغیر کړۍ',
    New: 'د بست اضافه کول',
    Update: 'د بست تغیرول',
    Grade: 'بست',
  },

  //GradeStep
  GradeStep: {
    Title: 'د بست قدم: د قدمونو لیسټ',
    GradeStepList: 'د بست د قدمونو لیسټ',
    Placeholder: 'د بست قدمونه د انګلیسي نوم، پښتو نوم، دري نوم یا د کوډ په اساس ولټوۍ...',
    AddTitle: 'د بست قدم: د قدم اضافه کول',
    CreateGradeStep: 'یو نوی د بست قدم اضافه کړۍ',
    UpdateTitle: 'د بست قدم: د قدم تغیرول',
    EditGradeStep: 'د بست قدم تغیر کړۍ',
    New: 'د بست قدم اضافه کول',
    Update: 'د بست قدم تغیرول',
    GradeStep: 'د بست قدم',
  },
  //PositionTitle
  PositionTitle: {
    Title: 'د دندې عنوان: لیسټ',
    PositionTitleList: 'د دندې د عنوانونو لیسټ',
    Placeholder: 'د دندې عنوان د انګلیسي نوم، پښتو نوم، دري نوم یا د کوډ په اساس ولټوۍ...',
    AddTitle: 'د دندې عنوان: اضافه کول',
    CreatePositionTitle: 'یو نوی د دندې عنوان اضافه کړۍ',
    UpdateTitle: 'د دندې عنوان: تغیرول',
    EditPositionTitle: 'د دندې عنوان تغیر کړۍ',
    New: 'د دندې عنوان اضافه کول',
    Update: 'د دندې عنوان تغیرول',
    PositionTitle: 'د دندې عنوان',
  },

  //JobPosition
  JobPosition: {
    Title: 'د بست عنوان: لیسټ',
    JobPositionList: 'د بست د عنوانونو لیسټ',
    Placeholder: 'د بست عنوان د انګلیسي نوم، پښتو نوم، دري نوم یا د کوډ په اساس ولټوۍ...',
    AddTitle: 'د بست عنوان: د بست اضافه کول',
    CreateJobPosition: 'یو نوی د بست عنوان اضافه کړۍ',
    UpdateTitle: 'د بست عنوان: تغیرول',
    EditJobPosition: 'د بست عنوان تغیر کړۍ',
    New: 'د بست عنوان اضافه کول',
    Update: 'د بست عنوان تغیرول',
    JobPosition: 'د بست عنوان',
    department: 'ریاست',
  },

  //News
  News: {
    ListTitle: 'خبرتیاوې: لیسټ',
    List: 'د خبرتیاوو لیسټ',
    News: 'خبرتیاوې',
    CreateTitle: 'خبرتیاوې: نوی اضافه کول',
    CreateNews: 'یوه نوې خبرتیا اضافه کړۍ',
    NewsDate: 'د خبرتیانیټه',
    Title: 'عنوان',
    Description: 'محتویات',
    SelectDepartment: 'ریاستونه غوره کړۍ',
    AddAttachments: 'ضمیمه اضافه کړۍ',
    Placeholder: 'مهرباني وکړۍ خبرتیاوې د عنوان، محتویات او ویاند په اساس ولتوۍ...',
    Speaker: 'ویاند',
    Location: 'ځای',
    UpdateTitle: 'خبرتیاوې: تغیرول',
    UpdateNews: 'خبرتیا تغیر کړۍ',
    Create: 'د خبرتیا اضافه کول',
    Update: 'د خبرتیا تغیرول',
    SelectAll: 'د ټولو انتخابول',
    ShowNotification: 'د خبرتیا ښودل',
    True: 'هو',
    False: 'نه',
    WriteContentsHere: 'خپل محتویات دلته ولیکۍ...',
    StartTime: 'د پیل وخت',
    EndTime: 'د پای وخت',
    FreshNews: 'نوې خبرتیاوې',
    Download: 'ډاونلوډ',
  },

  //Administrative Forms
  TrainingVideo: {
    Title: 'د روزنې ویډیو ګانې: لیسټ',
    List: 'د روزونکو ویډیو ګانو لیسټ',
    CreateTitle: 'د روزنې ویډیو ګانې: اضافه کول',
    CreateTrainingVideo: 'یوه نوې روزونکې ویډیو اضافه کړۍ',
    //Download: 'Download',
    UpdateTitle: 'د روزنې ویډیو ګانې: تغیرول',
    UpdateTrainingVideo: 'روزونکې ویډیو تغیر کړۍ',
    Create: 'د روزنې ویډیو اضافه کول',
    Update: 'د روزنې ویډیو تغیرول',
    //FormInfo: 'Form Information',
    Placeholder: 'د روزنې ویډیو دلته ولټوۍ...',
    Application: 'اپلیکیشن',
    TrainingVideo: 'د روزنې ویډیو ګانې',
    VideoPlayer: 'ویډیو پلیر',
    PlayerTitle: 'د روزنې ویډیو ګانې: ویډیو پلیر',
    FilterByApp: 'د اپلیکیشن په اساس فلټر',
    VideoList: 'د ویډیوګانو لیسټ',
    PleaseSelectAVideo: 'مهرباني وکړۍ یوه ویډیو غوره کړۍ',
    DariTitle: 'عنوان په دري کې',
    PashtoTitle: 'عنوان په پښتو کې',
    AddDariVideo: 'دري ویډیو اضافه کړۍ',
    AddPashtoVideo: 'پښتو ویډیو اضافه کړۍ',
    AddPoster: 'د ویډیو تصویر اضافه کړۍ',
    AddAttachments: 'د ویډیو اضافه کول',
  },

  //Welcome
  Welcome: {
    Title: 'د افغانستان د اوبو او انرژۍ مدیریتي سیسټم (AWEES) ته ښه راغلاست',
    Text: 'د افغانستان د انرژۍ او اوبو وزارت  د افغانستان په اسلامي امارت کې د ټولو پالیسیو، مقرراتو، پراختیایي پروژو او د اوبو او انرژۍ د سکتور د همغږۍ مسوولیت په غاړه لري.',
  },
  Report: {
    FromDate: 'له نیټې',
    ToDate: 'تر نیټې',
    Dashboard: 'ډشبورډ',
    ExpenseReport: 'د لګښت راپور',
    ExpenseTransaction: 'د لګښت معامله',
    LoanReport: 'د پور راپور',
    LoanTransaction: 'د پور معامله',
    TradeReport: 'د تجارت راپور',
    TradeTransaction: 'د تجارت معامله',
    Filter: 'فلټر',
    Print: 'چاپ',
    Reset: 'ریسیټ',
    GrandTotal: 'ټول ټال',
    No: 'نمبر',
    Branch: 'څانګه',
    LoanType: 'د پور ډول',
    CurrencyType: 'د اسعارو ډول',
    Amount: 'مقدار',
    Paid: 'ورکړل شوی',
    Remain: 'پاتې',
    Taken: 'اخیستل شوی',
    Given: 'ورکړل شوی',
    MainAssetCode: 'د اصلي شتمنۍ کوډ',
    UserName: 'د کارن نوم',
    Date: 'نېټه',
    Description: 'تشریح',
    ExpenseType: 'د لګښت ډول',
    Dollor: 'ډالر',
    Afghani: 'افغانۍ',
    ProfitAmount: 'د ګټې مقدار',
    LossAmount: 'د زیان مقدار',
    debitAmount: 'د پور ورکولو اندازه',
    creditAmount: 'د اعتبار اندازه',
    balanceAmount: 'د توازن اندازه',
    transactionDate: 'د معامله نیټه',
    MainAssetReport: 'د اصلي شتمنۍ راپور',
    Partner: 'شریک',
    partnerPhone: 'د شریک ټیلیفون',
    loanAmount: 'د پور مقدار',
    paidAmount: 'تادیه شوې اندازه',
    remainAmount: 'پاتې اندازه',
    expenseType: 'د لګښت ډول',
    tradeAmount: 'د سوداګرۍ اندازه',
    profitAmount: 'د ګټې اندازه',
    lossAmount: 'د تاوان اندازه',
  },
  AssetDetails: {
    MainAssetReport: 'د اصلي شتمنۍ راپور',
    AccountDetails: 'د حساب تفصیل',
    TotalCreditAmount: 'د ټولې کرېډېټ اندازه',
    TotalDebitAmount: 'د ټولې ډیبیټ اندازه',
    CurrentBalance: 'اوسنی موجودي',
    AssetTracking: 'د شتمنۍ تعقیب',
    LoanTracking: 'د پور تعقیب',
    ExpenseTracking: 'د لګښت تعقیب',
    TradeTracking: 'د سوداګرۍ تعقیب',
    WidthrawalTracking: 'د پیسو ایستلو تعقیب',
    MembersBalance: 'د غړو موجودي',
  },
  EmployeeProperty: {
    PopertyDetails: 'د ملکیت جزئیات',
    EmployeePropertyDetails: 'د کارمند ملکیت جزئیات',
    Name: 'نوم',
    Category: 'کټګوري',
    Model: 'ماډل',
    Price: 'بیه',
    Details: 'تفصیل',
  },
  Npfx: {
    NpfxWellcome: 'د NPFX شرکت د مدیریت سیسټم ته ښه راغلاست',
    NPFXMarket: 'NPFX مارکیت',
    TodayTotalExpense: 'د نن ورځې ټول لګښت',
    CurrentBalance: 'اوسنی بیلانس',
    TodayTotalLoss: 'د نن ورځې ټول زیان',
    TodayTotalProfit: 'د نن ورځې ټول ګټه',
    TodayTotalTrade: 'د نن ورځې ټول تجارت',
    TradeChart: 'د تجارت جدول',
    Profit: 'ګټه',
    Loss: 'زیان',
    Day: 'ورځ',
    Week: 'اونۍ',
    Month: 'میاشت',
    Year: 'کال',
    Period: 'موده',
    DataPoints: 'د معلوماتو ټکي',
    Categories: 'کتګورۍ',
    TotalAmount: 'ټول مقدار',
    ExpensesByCategories: 'مصارف په کتګوریو کې',
    MemberBalance: 'د غړو بیلانس',
  },
  dmtsLookuptable: 'د اسنادو د تعقیب او مدیریت سیسټم',
  department: 'ریاست',
  depLevel: 'ریاست درجه',
  docType: 'د سند ډول',
  outsideOrganization: 'بهرنی اداری',
  docLinkType: 'د سند نښلولو ډول',
  processStatus: 'د پروسی حالت',
  docLevel: 'د سند درجه',
  language: 'ژبه',
  docFileType: 'بهرنی /داخلی اسناد',
  application: 'سیستمونه',
  representator: 'استازی',
  itsmsLookuptable: 'د معلوماتي ټکنالوژۍ د خدماتو سیسټم',
  group: 'ګروپ',
  requestCatagory: 'د غوښتنې بڼه',
  actionRequest: 'د غوښتنې عمل',
  stepDescription: 'د پروسی تشریحات',
  software: 'سافتویر',
  itGoods: 'د معلوماتی تکنالوژی پرزی',
  dmts: 'د اسنادو مدیریت او تعقیب سیسټم',
  internaldocument: 'داخلی اسناد',
  list: 'لیست',
  Sendlist: 'د لیږل شوو اسنادو لیسټ',
  Receivelist: 'د راغلو اسنادو لیسټ',
  tahriratList: 'د تحریراتو اسنادو لیسټ',
  ForwardedList: 'مخ پروړاندې لیږل شوی سندونه',
  create: 'اضافه کول',
  externaldocument: 'خارجی اسناد',
  reception: 'د استقبالیه سیسټم',
  cardType: 'د کارټ ډول',
  card: 'کارټ',
  visitingType: 'د مراجعې ډول',
  VisitorList: 'د مراجعینو لیسټ',
  visitor: 'مراجعه کوونکی',
  RegisterVisitor: 'مراجعه کوونکی اضافه کول',
  visitorSchedule: ' مراجعه کوونکي مهال ویش',
  viewSchedule: 'د مهال ویش کتل',
  cardTypeReport: 'د کارټ ډول راپور',
  requestTracking: 'د پیشنهادونو تعقیب',
  itsms: 'د معلوماتی تکنالوژی د خدماتو سیسټم',
  request: 'غوښتنه',
  pendingRequest: 'منتظر شوې غوښتنې',
  applicant: 'غوښتونکی',
  StatisticalReport: 'احصایوي راپور',
  ArchLookupTable: 'د آرشیف سیسټم',
  archiveinfo: 'د آرشیف معلومات',
  archive: 'آرشیف',
  archiveReport: 'د آرشیف د معلوماتو راپور',
  archCreate: 'د آرشیف معلوماتو اضافه کول',
  cupboard: 'الماری',
  shelf: 'تاقچه',
  block: 'بلاک',
  archiveDocType: 'د آرشیف سند ډول',
  archiveProject: 'د آرشیف پروژې',
  year: 'کال',
  ums: 'د کارونکي مدیریت سیسټم',

  //UMS
  UMS: 'د کاروونکي مدیریتي سیسټم',
  UserManagement: 'د کاروونکو مدیریت',
  Permission: 'اجازه',
  Role: 'رول',
  createUser: 'د کاروونکي جوړول',
  Userlist: 'د کاروونکو لیسټ',
  UserLogList: 'د کاروونکو تاریخچه',
  changePassword: 'د پټ نوم تغیرول',
  hr: 'بشري سرچینه',
  emp: 'کارکوونکی',
  employeePosition: 'د کارکوونکي بست',
  //HR
  HRLookupTables: 'د بشري سرچینو سیسټم',
  cardGenerationType: 'د کارټ د تولید ډول',
  contractType: 'د قرارداد ډول',

  educationTitle: 'تحصیلی رشته',
  educationLevel: 'تحصیلی درجه',

  grade: 'بست',
  gradeStep: 'د بست قدم',
  positionTitle: 'د دندې عنوان',
  jobPosition: 'د بست عنوان',
  //CommonLookUps
  CommonLookUps: 'عمومي',
  //News
  news: 'خبرتیاوې',
  newsList: 'د خبرتیاوو لیسټ',
  addNews: 'د خبرتیا اضافه کول',
  //Training Videos
  trainingVideo: 'د روزنې ویډیو ګانې',
  addTrainingVideo: 'د ویډیو اضافه کول',
  trainingVideolist: 'د ویډیوګانو لیست',
  trainingVideoPlayer: 'د ویډیوګانو ښودل',

  apps: 'اپلیکیشنونه',
  app: `اپلیکیشن`,
  user: `کارمند`,
  edit: `تعديل`,
  shop: `متجر`,
  blog: `مقالات`,
  post: `بريد`,
  mail: `بريد`,
  chat: `دردشة`,
  cards: `البطاقات`,
  posts: `المشاركات`,
  kanban: `كانبان`,
  general: 'عمومیات',
  banking: `الخدمات المصرفية`,
  booking: `بکنگ`,
  profile: `الملف الشخصي`,
  account: `الحساب`,
  product: `المنتوج`,
  invoice: `فاتورة`,
  details: `جزیات`,
  checkout: `الدفع`,
  calendar: `التقويم`,
  analytics: `التحليلات`,
  ecommerce: `التجارة الإلكترونية`,
  management: `مدیریت`,
  menu_level_1: `مستوى القائمة 1`,
  menu_level_2: `مستوى القائمة 2`,
  menu_level_3: `مستوى القائمة 3`,
  item_disabled: `العنصر معطل`,
  item_label: `تسمية العنصر`,
  item_caption: `عنوان العنصر`,
  description: `وصف`,
  other_cases: `حالات اخرى`,
  item_by_roles: `عنصر حسب الأدوار`,
  only_admin_can_see_this_item: `يمكن للمسؤول فقط رؤية هذا العنصر`,
  report: 'گزارشونه',
  NoRecordFound: 'هیڅ ریکارډ ونه موندل شو',
  PropertyMenu: 'د ملکیت مدیریت',
};

export default dr;
