// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  GoogleAuth: {
    RecoveryCode: 'Recovery Code',
    loginGoogleAuth: 'Login with Google Authenticator Code',
    loginRecovery: 'Login with Recovery Code',
    scan: 'Scan this QR code with Google Authenticator',
    enterSixDigit: 'Enter 6-Digit Code',
    verify: 'Verify',
    RecoveryCodes: 'Recovery Codes',
    NoCode: ' No recovery codes available.',
    SharedKey: 'Google Auth Shared Key',
    Enable2fa: 'Enable 2FA',
    EnableTwoFactor: 'Enable Two-Factor Authentication',
    note: 'Click the button below to generate your Google Authenticator QR code.',
    Disable2fa: 'Disable 2fa',
  },
  Enable2fa: `2-Factor Authentication`,
  CRUD: {
    Create: `Create`,
    CreateTrade: `Create Trade`,
    WithdraFromAccount: `Withdraw Cash From account`,
    DepositToAccount: `Deposit Cash To account`,
    DepositToUser: `Transfer Cash`,
    CreateLoan: `Create Loan`,
    TakeOrGiveLoan: `Take/Give Loan`,
    PayTakenLoan: `Pay Loan`,
    TradeEntry: `Enter Trades`,
    DepositCash: `Deposit Cash`,
    WithDrawCash: `Withdraw Cash`,
    TakePaidLoan: `Receive Loan`,
    Update: `Update`,
    Delete: `Delete`,
    List: `List`,
    Back: `Back`,
    back: 'Back',
    Save: 'Save',
    DeleteTitle: ` Are you sure to Delete?`,
    Remarks: `Remarks`,
    Reject: 'Reject ',
    Detail: `Detail`,
    Cancle: 'Cancel',
    Remove: 'Remove',
    Reset: 'Reset',
    BackToList: 'Back To List',
    AddAttchment: 'Add New Attachment',
    AddNew: 'Add New',
    Approve: 'Approve',
    Search: 'Search',
    Add: 'Add',
    //
    SelectOption: 'Select Options',
    Yes: 'Yes',
    No: 'No',
    SearchButton: 'Search',
    SearchPlaceholder: 'Search...',
    Close: 'Close',
    // propery
    ChangeStatus: 'Change Status',
    Assign: 'Assign',
    Pay: 'Pay',
    Assignment: 'Assign To',
    Payment: 'Due Payment',
  },

  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },

  MainAsset: {
    AddTitle: `Add Account`,
    UpdateTitle: `Update Account`,
    MainAssetList: `Accounts List`,
    New: `New Account`,
    Update: `Update Accounts`,
    AssetType: `Account Type`,
    Owneruser: `Owner User`,
    DepositDate: `Deposit Date`,
    BalanceAmount: `Account Balance`,
    Description: `Description`,
    MainAsset: `Account`,
  },
  AssetType: {
    AssetTypeList: 'Account Type List',
    Placeholder: 'Search Account Type here...',
    CreateAssetType: 'Create Account Type',
    AssetType: `Edit Account Type`,
    New: 'New Account Type',
    Update: 'Update Account Type',
    Title: 'Account Type',
    AddTitle: 'Add Account Type',
    UpdateTitle: 'Update Account Type',
  },
  Branch: {
    Branch: `Branch`,
    ParentName: `Parent Name`,
    ModifiedOn: `Modified on`,
    BranchList: `Branch List`,
    Placeholder: `Search branch here`,
    Address: `Branch Address`,
    Parent: `Branch Parent`,
    CreateBranch: `Create Branch`,
    EditBranch: `Edit Branch`,
    New: `New Branch`,
    Update: `Update Branch`,
    AddTitle: 'Add Branch',
    UpdateTitle: 'Update Branch',
  },
  PaymentType: {
    PaymentType: `Payment Type`,
    ParentName: `Parent Name`,
    ModifiedOn: `Modified on`,
    PaymentTypeList: `Payment Type List`,
    Placeholder: `Search Payment Type here....`,
    Address: `Payment Type Address`,
    Parent: `Payment Type Parent`,
    CreatePaymentType: `Create Payment Type`,
    EditPaymentType: `Edit Payment Type`,
    New: `New Payment Type`,
    Update: `Update Payment Type`,
    AddTitle: 'Add Payment Type',
    UpdateTitle: 'Update Payment Type',
  },
  Partners: {
    Partners: `Partners`,
    ParentName: `Parent Name`,
    ModifiedOn: `Modified on`,
    PartnersList: `Partners List`,
    Placeholder: `Search Partners here....`,
    Address: `Partners Address`,
    Parent: `Partners Parent`,
    CreatePartners: `Create Partners`,
    EditPartners: `Edit Partners`,
    New: `New Partners`,
    Update: `Update Partners`,
    AddTitle: 'Add Partners',
    UpdateTitle: 'Update Partners',
  },
  CurrencyType: {
    CurrencyType: `Currency Type`,
    ParentName: `Parent Name`,
    ModifiedOn: `Modified on`,
    CurrencyTypeList: `Currency Type List`,
    Placeholder: `Search Currency Type here....`,
    Address: `Currency Type Address`,
    Parent: `Currency Type Parent`,
    CreateCurrencyType: `Create Currency Type`,
    EditCurrencyType: `Edit Currency Type`,
    New: `New Currency Type`,
    Update: `Update Currency Type`,
    AddTitle: 'Add Currency Type',
    UpdateTitle: 'Update Currency Type',
  },
  LoanType: {
    LoanType: `Loan Type`,
    Title: `Loan Type`,
    LoanTypeList: `Loan Type List`,
    Placeholder: `Search Loan Type here`,
    CreateLoanType: 'Create Loan Type',
    EditLoanType: 'Edit Loan Type',
    New: 'New Loan Type',
    Update: 'Update Loan Type',
    AddTitle: 'Add Loan Type',
    UpdateTitle: 'Update Loan Type',
  },

  Categorys: {
    Categorys: `Categorys`,
    Title: `Categorys`,
    CategorysList: `Categorys Type List`,
    Placeholder: `Search Categorys here`,
    CreateCategorys: 'Create Categorys',
    EditCategorys: 'Edit Categorys',
    New: 'New Categorys',
    Update: 'Update Categorys',
    AddTitle: 'Add Categorys',
    UpdateTitle: 'Update Categorys',
  },
  PropertyConditions: {
    PropertyConditions: `Property Conditions`,
    Title: `Property Conditions`,
    PropertyConditionsList: `Property Conditions Type List`,
    Placeholder: `Search Property Conditions here`,
    CreatePropertyConditions: 'Create Property Conditions',
    EditPropertyConditions: 'Edit Property Conditions',
    New: 'New Property Conditions',
    Update: 'Update Property Conditions',
    AddTitle: 'Add Property Conditions',
    UpdateTitle: 'Update Property Conditions',
  },
  Property: {
    AddTitle: 'Property Create',
    PropertyList: 'Property List',
    Condition: 'Status',
    UpdateTitle: 'Update Property',
    DetailsTitle: 'Property Details',
    New: 'New Property',
    Update: 'Update Property',
    Employee: 'Employee Name',
    PropertyConditions: `Property Conditions`,
    Model: `Model`,
    PaymentDate: `Payment Date`,
    Payments: `Payments`,
    Payment: `Payment`,
    Price: `Price`,
    Assignments: `Assignments`,
    Assignment: `Assignment`,
    AmountPaid: `Amount Paid`,
    PayableAmount: `Payable Amount Paid`,
    RemainingBalance: `Remaining Balance`,
    MaintenanceHistory: `Maintenance History`,
    Placeholder: `Search here ....`,
  },

  Partner: {
    Partner: `Partner `,
    PartnerPhone: `Partner Phone `,
  },
  Department: {
    Dashboard: 'Dashboard',
  },
  TradeTracking: {
    TradeTracking: 'Trade History List',
    Title: 'Trade History',
    New: 'New Trade',
    Update: 'Update Trade',
    EnterTrade: 'Enter Trades',
    TradeAmount: 'Trade Amount',
    ProfitAmount: 'Profit Amount',
    LossAmount: 'Loss Amount',
    CreateTradeTracking: 'Create Trade',
    Placeholder: 'Search Trades',
  },
  ExpenseTracking: {
    ExpenseTracking: 'Expense History List',
    Title: 'Expense History',
    New: 'New Expense',
    Create: 'Create Expense',
    Edit: 'Update Expense',
    Update: 'Update Expense',
    EnterExpense: 'Enter Expenses',
    ExpenseAmount: 'Expense Amount',
    ProfitAmount: 'Profit Amount',
    LossAmount: 'Loss Amount',
    CreateExpenseTracking: 'Create Expense',
    Placeholder: 'Search Expenses',
  },
  WithdrawalTracking: {
    WithdrawalTracking: 'Withdrawal History List',
    Title: 'Withdrawal History',
    New: 'New Withdrawal',
    Create: 'Create Withdrawal',
    Edit: 'Update Withdrawal',
    CreateDeposit: 'Create Deposit',
    EditDeposit: 'Update Deposit',
    DepositToAccount: 'Deposit To Account',
    Update: 'Update Withdrawal',
    EnterWithdrawal: 'Enter Withdrawals',
    WithdrawalAmount: 'Withdrawal Amount',
    ProfitAmount: 'Profit Amount',
    LossAmount: 'Loss Amount',
    CreateWithdrawalTracking: 'Create Withdrawal',
    Placeholder: 'Search Withdrawals',
  },
  ExpenseType: {
    ExpenseType: `Expense Type`,
    ExpenseTypeList: `Expense Type List`,
    Placeholder: `Search Expense Type Here...`,
    CreateExpenseType: 'Create Expense Type',
    EditExpenseType: `Edit Expense Type`,
    New: `New Expense Type`,
    Update: `Update Expense Type`,
  },
  npxChartDashboard: {
    TotalUsers: `Total Users`,
    TotalBracnhes: `Total Branches`,
  },
  //========================== Menu Translation ========================
  AssetManagementMenu: `Account Management`,
  LoanTrackingMenu: `Loan Management`,
  TradeTrackingMenu: `Trade Management`,
  ExpenseTrackingMenu: `Expense Management`,
  WithdrawalTrackingMenu: `Withdraw Management`,
  lookuptable: `System Settings`,
  BranchMenu: `Branch`,
  ExpenseTypeMenu: `Expense Type`,
  AssetTypeMenu: `Account Type`,
  LoanTypeMenu: `Loan Type`,
  PaymentTypeMenu: `Payment Type`,
  CurrencyTypeMenu: `Currency Type`,
  PartnersMenu: `Partners`,
  // ========================= End Menu Translation =====================

  LoanTracking: {
    AddTitle: `Create Loan`,
    UpdateTitle: `Update Loan`,
    CreateLoanTracking: `Create New Loan`,
    EditLoanTracking: `Edit Loan`,
    LoanTrackingList: `Loans List`,
    New: `New Loan`,
    Update: `Update Loan`,
    LoanAmount: `Loan Amount`,
    PaidAmount: `Paid Amount`,
    RemainAmount: `Remain Amount`,
    Placeholder: `Search Loan Records`,
    GiveLoan: `Give Loan`,
    TakeLoan: `Take Loan`,
  },

  //Login Page
  login: {
    SignInToMinistry: 'Sign In to NPFX Market Management System',
    WelcomeBack: 'Hi, Welcome Back',
    UserName: 'User Name',
    Password: 'Password',
    RememberMe: 'Remember Me',
    Login: 'Login',
    ForgotPassword: 'Forgot Password?',
    SendEmail:
      'Please enter the email address associated with your account and We will email you a link to reset your password.',
    EmailMustBe: 'Email must be a valid email address',
    EmailIsRequired: 'Email is required',
    EmailAddress: 'Email Address',
    SendRequest: 'Send Request',
    RequestSent: 'Request sent successfully!',
    WeHaveSent: 'We have sent a 4-digit confirmation email to your email.',
    PleaseEnter: 'Please enter the code in below box to verify your email.',
    VerifyCode: 'Verify Code',
    DontHaveACode: `Don't have a code?`,
    ResendCode: 'Resend code',
    ConfirmNewPassword: 'Confirm New Password',
    ChangePassword: 'Change Password',
    PasswordMustBe: 'Password must be at least 6 characters',
    PasswordIsRequired: 'Password is required',
    ConfirmPassword: 'Confirm password is required',
    PasswordMatch: 'Passwords must match',
    EmailNotExist: 'Email does not exist, please register your account!',
    InvalidCode: 'Invalid code, please try again!',
  },

  Pagination: {
    RowsPerPage: 'Rows Per Page',
    Dense: 'Dense',
    Of: 'of',
  },
  Validation: {
    EnglishName: 'English Name is Required',
    DariName: 'Dari Name is Required',
    PashtoName: 'Pashto Name is Required',
    EnglishSurName: 'English Sur Name is Required',
    PashtoSurName: 'Pashto Sur Name is Required',
    Code: 'Code is Required',
    Remark: 'Please Enter Remarks',
    Address: 'Address is Required.',
    Name: 'Name is Required.',
    ContactNumber1: 'Contact Number 1 is Required',
    ContactNumber2: 'Contact Number 2 is Required',
    Email: 'Email is Required.',
    Detail: 'Detail is Required',
    User: 'User is required.',
    Comments: 'Comments is required.',
    Description: 'Description is required',
    FatherEnglishNameIsRequired: 'Father English Name is required',
    FatherDariNameIsRequired: 'Father Pashto Name is required',
    GrandFatherEnglish: 'Grand Father English Name is required',
    GrandFatherPashto: 'Grand Father Pashto Name is required',
    GenderIsRequired: 'Gender is requiree',
    PositionIsRequired: 'Position is required',
    DegreeIsRequired: 'Please select Employee Degree',
    DateOfBirth: 'Date of Birth is Required',
    TemporaryAddress: 'Temporary Address is Required',
    PermanentAddress: 'Permanent Address is Required',
    CNIC: 'CNIC Number is Required',
    Gender: 'Please Select the Gender, it is Required',
    JoinDate: 'Join Date is Required',
    PersonalEmail: 'Personal Email is Required',
    OfficialEmail: 'Official Email is Required',
    Province: 'Please Select the Province, it is Required',
    District: 'Please Select the District, it is Required',
    BloodGroup: 'Please Select the Blood Group, it is Required',
    LeaveDate: 'Leave Date is Required',
    LeaveRemarks: 'Please add Leave Remarks',
    isCurrent: 'Is it Current Employee',
    ProfilePhoto: 'Profile Photo is Required',
    EmployeeHealthStatus: 'Please Select Employee Health Status, it is Required',
    CountryNameIsRequired: 'Please Select Country Name, it is Required',
    //EmailIsRequired: "Email is required",
    //Main Activity
    ProcessStatus: 'Please Select Process Status',
    StartDate: 'Please Select Start Date',
    EndDate: 'Please Select End Date',
    description: 'Description is required',
    //ContractDetailsType
    contractType: 'Please Select Contract Type, it is Required',
    positionTitle: 'Please Select Position Title, it is Required',
    jobPosition: 'Please Select Job Position, it is Required',
    grade: 'Please Select Grade, it is Required',
    gradeStep: 'Please Select Grade Step, it is Required',
    department: 'Please Select Directorate, it is Required',
    //EducationLevelDetails
    educationLevel: 'Please Select Education Level, it is Required',
    educationTitle: 'Please Select Education Title, it is Required',
    englishUniName: 'Educational Authority English Name is Required',
    pashtoUniName: 'Educational Authority Pashto Name is Required',
    dariUniName: 'Educational Authority Dari Name is Required',
    comment: 'Comment is required',
    DoubleClick: 'Double Clickd to Show',
    RequestDoubleClick: 'Double Clickd to Show',
    DocumentDetails: 'Document Details',
    RequestDetails: 'Request Details',
    ClickHere: 'Click here to highlite details',
    //
    FromDate: 'From Date is required',
    ToDate: 'To Date is required',
    //Grade
    ContractType: 'Please select Contract Type, It is required',
    DistrictCode: 'District Code is required',
    EmployeeIsRequired: 'Employee is required',
    TitleIsRequired: 'Title is required',
    // NPFX
    ExpenseType: 'Expense Type is Required',
    Account: 'Account is Required',
    Branch: 'Branch is Required',
    Date: 'Date is Required',
    Amount: 'Amount is Required',
    LoanAmount: 'Loan Amount is Required',
    LoanType: 'Loan Type is Required',
    CurrencyType: 'Currency Type is Required',
    AccountType: 'Account Type is Required',
    OwnerUser: 'Owner User is Required',
    DepositDate: 'Deposit Date is Required',
    BalanceAmount: 'Balance Amount is Required',
    DepositAmmount: 'Deposit Ammount is Required',
    ToUser: 'To User is Required',
    TradeAmount: 'Trade Amount is Required',
    WithdrawalAmount: 'Withdrawal Amount is Required',
    // Property Validation
    PropertyIsRequired: 'Property is Required',
    ConditionId: 'Condition is Required',
    Category: 'Category is Required',
    Price: 'Price is Required',
    AmountPaid: 'AmountPaid is Required',
    PaymentDate: 'Payment Date is Required',
  },

  // Fields names which repeadedly uses in tables
  GeneralFields: {
    Id: 'Id',
    Name: 'Name',
    Status: 'Status',
    NameInEnglish: 'English Name',
    NameInPashto: 'Pashto Name',
    NameInDari: 'Dari Name',
    Code: 'Code',
    Action: 'Action',
    StartDate: 'Start Date',
    EndDate: 'End Date',
    Remarks: 'Remarks',
    Description: 'Description',
    Date: 'Date',
    DueDate: 'Due Date',
    OneDollor: '1 USD =',
    AFN: 'AFN',
    USD: 'USD',
    SelectAll: 'Select All',
  },

  Tostar: {
    CreateSuccess: 'Successfuly Created!',
    UpdateSuccess: 'Successfuly Updated',
    RejectSuccess: 'Rejected Successfully!',
    RejectFaild: 'Faild To Reject!',
    UserActivated: 'User Activated!',
    UserDeactivated: 'User Deactivated!',
    DeleteSuccess: 'Deleted Successfully',
    DeleteFailed: 'Delete Failed',
    DataIsAlreadyExist: 'Data is already exist',
    LoginSuccess: 'Login Successfuly!',
    TaskReAsign: 'Task ReAsigned Successfuly',
    ApproveSuccess: 'Successfuly Approved',
    CardAssignedSuccess: 'Card Assigned Successfuly!',
    NewTicket: 'New Ticket',
    TicketCompleted: 'Ticket Completed',
    TicketRejected: 'Ticket Rejected',
    TicketReceived: 'Ticket Received',
    MarkedAsRead: 'Marked as Read',
    MarkedAsUnRead: 'Marked as Unread!',
    ChangePassword: 'Password changed successfuly',
    ReceivedSuccess: 'Document successfuly received',
  },
  ProcessStatus: {
    Title: 'Process Status: List',
    CreateTitle: 'Process Status: Create New',
    ProcessStatusList: 'Process Status List',
    ProcessStatus: 'Process Status',
    Placeholder: 'Search Process Status',
    NewProcessStatus: 'New Process Status',
    UpdateProcessStatus: 'Update Process Status',
    CreateProcessStatus: 'Create Process Status',
    EngName: 'Eng Name',
    PsName: 'Pashto Name',
    DrName: 'Dari Name',
    DocCode: 'Document Code',
    Action: 'Action',
    Id: '#',
  },

  APPlication: {
    App: 'Application',
    AppList: 'Application List',
    Placeholder: 'Search Application...',
    Id: 'ID',
    Action: 'Action',
    Title: 'Title',
    Abbrevation: 'Abbrivation',
    IconClass: 'Icon Class',
    DefaultRoute: 'Default Route',
    Area: 'Area',
    Description: 'Description',
    NewApp: 'New Application',
    UpdateApp: 'Update Application',
    CreateApp: 'Create Application',
  },

  //Notifications
  Notifications: {
    Notifications: 'Notifications',
    New: 'NEW',
    BeforeThat: 'BEFORE THAT',
    ViewAll: 'View All',
    From: 'From',
    ViewMore: 'View More',
  },
  //HR Employee
  Employee: {
    Title: 'Employee: Employee List',
    EmployeeList: 'Employee List',
    Id: 'ID',
    Name: 'Name',
    FatherName: 'Father Name',
    EmployeeType: 'Employee Type',
    Department: 'Directorate',
    Position: 'Position',
    Action: 'Action',
    Email: 'Email',
    PhoneNumber: 'Phone Number',
    Placeholder: 'Search Employee by Name, Directorate or Phone Number...',
    CreateTitle: 'Employee: Create Employee',
    CreateEmployee: 'Create a New Employee',
    UpdateEmployee: 'Update Employee',
    NewEmployee: 'New Employee',
    UpdateEmp: 'Update Employee',
    EnglishName: 'Name in English',
    DariName: 'Local Name ( Position Title)',
    PashtoName: 'Name in Pashto',
    FatherEnglishName: 'Father Name in English',
    FatherDariName: 'Father Name in Pashto',
    Gender: 'Gender',
    Male: 'Male',
    Female: 'Female',
    Official: 'Official',
    Contractual: 'Contractual',
    Intermittently: 'Intermittently',
    JoinDate: 'Join Date',
    EmployeeDegree: 'Employee Degree',
    BloodGroup: 'Blood Group',
    AttendanceId: 'Attendance ID',
    Details: 'Details',
    CreateUser: 'Create Account',
    ContractDetails: 'Job History',
    CardDetails: 'Card Details',
    EducationalLevelDetails: 'Education Level',
    EnglishBioData: 'English Bio Data Information',
    PashtoDariBioData: 'Pashto/Dari Bio Data Information',
    ProfilePhoto: 'Profile Photo',
    CropImage: 'Crop Image',
    TazkiraType: 'Tazkira Type',
    ETazkira: 'E-Tazkira',
    PaperBaseTazkira: 'Paper Base Tazkira',
    JoldNo: 'Jold No',
    PageNo: 'Page No',
    RegNo: 'Reg No',
    //
    englishSurName: 'Sur Name in English ',
    pashtoSurName: 'Sur Name in Pashto ',
    englishGrandFatherName: 'Grand Father Name in English',
    pashtoGrandFatherName: 'Grand Father Name in Pashto',
    dateOfBirth: 'Date of Birth',
    temporaryAddress: 'Temporary Address',
    permanentAddress: 'Permanent Address',
    attendanceId: 'Attendance Id',
    cnic: 'CNIC',
    leaveDate: 'Leave Date',
    leaveRemark: 'Leave Remarks',
    personalEmail: 'Personal Email',
    officialEmail: 'Official Email',
    rfidNumber: 'RFID Number',
    isCurrent: 'Is Current Employee',
    IsCurrentContract: 'Contract Status',
    emergencyPhoneNumber: 'Emergency Phone Number',
    registrationNumber: 'Registration Number',
    Province: 'Province',
    District: 'District',
    Employee: 'Employee',
    DoubleClick: 'Double Click to show ',
    EmployeeDetail: ' Details',
    //EmployeeDetails
    DetailsTitle: 'Employee: Details',
    EmployeeDetails: 'Employee Details',
    EmployeeHealthStatus: 'Employee Health Status',
    EmployeeGeneralInfo: 'Employee General Information',
    //Employee Report
    EmployeeReportTitle: 'Employee: Employee Report',
    EmployeeReport: 'Employees Report',
    HasAccount: 'Has Account',
    hasAccount: 'Yes',
    NoAccount: 'No',
    FullName: 'Full Name',
    Account: 'Account',
  },
  EmployeeProperty: {
    PopertyDetails: 'Property Details',
    EmployeePropertyDetails: 'Employee Property Details',
    Name: 'Name',
    Category: 'Category',
    Model: 'Model',
    Price: 'Price',
    Details: 'Details',
  },

  //ContractDetails
  ContractDetails: {
    Title: 'Contract Details: List',
    ContractDetailsList: 'Contract Details List',
    Placeholder: 'Search Contract Details...',
    AddTitle: 'Contract Details: Create',
    CreateContractDetails: 'Create Contract Details',
    UpdateTitle: 'Contract Details: Update',
    EditContractDetails: 'Edit Contract Details',
    New: 'Creation of Contract Details',
    Update: 'Updating of Contract Details',
    Active: 'Active',
    ContractWarning:
      'Warning: Save Button is disabled because the employee current contract is not ended/expired, Please update employee current contract.',
    BranchName: 'Branch Name',
    SalaryPerHour: 'Salary Per Hour',
    Action: 'Action',
    BackToEmployee: 'Back To Employee',
    Branch: 'Branch',
    currencyType: 'currency Type',
    salaryAmount: 'Salary Amount',
    positionTitle: 'Position Title',
  },
  //Employee Degree
  EmployeeDegree: {
    Title: 'Employee Degree: Employee Degree List',
    EmployeeDegreeList: 'Employee Degree List',
    Placeholder: 'Search Employee Degree...',
    CreateTitle: 'Employee Degree: Create Employee Degree',
    CreateEmployeeDegree: 'Create Employee Degree',
    EditEmployeeDegree: 'Edit Employee Degree',
    NewEmployeeDegree: 'New Employee Degree',
    UpdateEmployeeDegree: 'Update Employee Degree',
  },
  //Employee Position
  EmployeePosition: {
    Title: 'Employee Position: Employee Position List',
    EmployeePositionList: 'Employee Position List',
    Placeholder: 'Search Employee Position...',
    CreateTitle: 'Employee Position: Create Employee Position',
    CreateEmployeePosition: 'Create Employee Position',
    EditEmployeePosition: 'Edit Employee Position',
    NewEmployeePosition: 'New Employee Position',
    UpdateEmployeePosition: 'Update Employee Position',
  },
  //Pending Requests
  PendingRequests: {
    Title: 'Pending Requests',
    List: 'Pending Requests List',
    Placeholder: 'Search Pending Requests by Tracking Number, Request Number or User Name',
    ReAsign: 'ReAsign',
    ReAsignTitle: 'Do you want to ReAsign the task?',
  },

  //News
  News: {
    ListTitle: 'News: News List',
    List: 'News List',
    News: 'News',
    CreateTitle: 'News: Create News',
    CreateNews: 'Create a new News',
    NewsDate: 'News Date',
    Title: 'Title',
    Description: 'Description',
    SelectDepartment: 'Select Departments',
    AddAttachments: 'Add Attachments',
    Placeholder: 'Search News by Title, Description and Speaker...',
    Speaker: 'Speaker',
    Location: 'Location',
    UpdateTitle: 'News: Update News',
    UpdateNews: 'Update News',
    Create: 'Creating News',
    Update: 'Updating News',
    SelectAll: 'Select All',
    ShowNotification: 'Show Notification',
    True: 'Yes',
    False: 'No',
    WriteContentsHere: 'Write your descriptions here...',
    StartTime: 'Start Time',
    EndTime: 'End Time',
    FreshNews: 'Fresh News',
    Download: 'Download',
  },
  //Administrative Forms
  TrainingVideo: {
    Title: 'Training Videos: List',
    List: 'Training Videos List',
    CreateTitle: 'Training Videos: Create',
    CreateTrainingVideo: 'Add a New Training Video',
    //Download: 'Download',
    UpdateTitle: 'Training Videos: Update',
    UpdateTrainingVideo: 'Update Training Video',
    Create: 'Adding of Training Video',
    Update: 'Updating of Training Video',
    //FormInfo: 'Form Information',
    Placeholder: 'Search Training Videos here...',
    Application: 'Application',
    TrainingVideo: 'Training Video',
    VideoPlayer: 'Video Player',
    PlayerTitle: 'Training Videos: Player',
    FilterByApp: 'Filter by Application',
    VideoList: 'Videos List',
    PleaseSelectAVideo: 'Please select a video',
    DariTitle: 'Dari Title',
    PashtoTitle: 'Pashto Title',
    AddDariVideo: 'Add Dari Video',
    AddPashtoVideo: 'Add Pashto Video',
    AddPoster: 'Add Video Poster',
    AddAttachments: 'Adding Attachments',
  },

  //User Management
  User: {
    user: 'User',
    newUser: 'New User',
    userList: 'User List',
    userName: 'User Name',
    fullUserName: 'Full User Name',
    email: 'Email',
    Branch: 'Branch',
    isActive: 'Is Active',
    unActive: 'UnActive',
    Active: 'Active',
    Banned: 'Banned',
    createUser: 'Create a New User',
    editUser: 'Edit User',
    Placeholder: 'Search User ...',
    phoneNumber: 'Phone Number',
    positionTitle: 'Position Title',
    Language: 'Language',
    userRoles: 'User Roles',
    AllowedDepartments: 'Allowed Directorates',
    AllowedSecurityLevels: 'Allowed Security Levels',
    AllowedDocumentType: 'Allowed Document Type',
    Organization: 'Organization',
    CreateUser: 'Create User',
    UpdateChanges: 'Update Changes',
    Allowed: 'Allowed *.jpeg, *.jpg, *.png',
    MaxSize: 'max size of',
    UploadPhoto: 'Upload Photo',
    UpdatePhoto: 'Update Photo',
    NoPositionAdded: 'No Position Added',
    SecurityLevel: 'Security Level',
    UserDetails: 'User Detatils',
    ResetPassword: 'Reset Password',
    NewPassword: 'New Password',
    ChangePassword: 'Change Password',
    CurrentPassword: 'Current Password',
    ConfirmNewPassword: 'Confirm New Password',
    Title: 'User: User List',
    active: 'Active',
    Deactive: 'Deactive',
    AppName: 'App Name',
    Description: 'Description',
    EnglishName: 'Name in English',
    Code: 'Code',
    PashtoName: 'Name in Pashto',
    DariName: 'Name in Dari',
    About: 'About',
    Contact: 'Contact',
    GeneralInfo: 'General Information',
    ProfileTitle: 'User: Profile',
    Profile: 'Profile',
    UserPosition: 'User Position',
    Employee: 'Employee',
    Representator: 'Representator',
    userPassword: 'User Password',
    department: 'Directorate',
    isManager: 'Is Executive Manager',
    Action: 'Action',
    ActionOn: 'Action On',
    IpAddress: 'IP Address',
    Message: 'Message',
    Server: 'Server',
    BrowserName: 'Browser Name',
    Os: 'OS',
    userLogTitle: 'User Log: List',
    userLogList: 'User Log List',
    ActiveUsers: 'Active Users',
    Managers: 'Managers',
    ToggleOrientation: 'ToggleOrientation',
    UserReport: 'Users Report',
    Day: 'Day',
    // Validation
    PleaseEnterNewPassword: 'Please Enter New Password',
    UserNameIsRequired: 'User Name is required',
    EmailIsRequired: 'Email is required',
    NumberFormat: 'Number Format Should be +937xxxxxxxxx',
    PhoneNumberIsRequired: 'Phone Number is required',
    PositionTitleIsRequired: 'Position Title is required',
    LanguageIsRequired: 'Language is required',
    PleaseEnterRmarks: 'Please Enter Remarks',
    CurrentPassIsRequired: 'Current Password is required',
    AtLeastChar: 'Password must be at least 6 charecters',
    NewPassIsRequired: 'New Password is required',
    passMustMatch: 'Passwords must match',
    PasswordIsRequired: 'Password is Required',
    NewPassRequiresAlpha: 'Password must include charecters',
    NewPassRequiresNumber: 'Password must include numbers',
    NewPassRequiresSpecialChar: 'Password must include special charecters',
  },
  //Account
  Account: {
    Home: 'Home',
    Profile: 'Profile',
    Logout: 'Logout',
  },
  //Role
  userRole: {
    Id: 'ID',
    Application: 'Application',
    Name: 'Name',
    Description: 'Description',
    Permission: 'Permission',
    TotalPermissions: 'Total Permissions',
    PermissionName: 'Permission Name',
    ControllerName: 'Controller Name',
    Method: 'Method',
    RoleList: 'Role List',
    Role: 'Role',
    Placeholder: 'Search Role ...',
    CreateNewRole: 'Create a New Role',
    EditRole: 'Edit Role',
    NewRole: 'New Role',
    RoleEdit: 'Edit Role',
    CreateRole: 'Create Role',
    UpdateRole: 'Update Role',
    RoleDetails: 'Details of Role',
    RoleName: 'Role Name',
    PermissionDetails: 'Permission Details',

    //Validation
    NameIsRequired: 'Name is required',
    AppIsRequired: 'Application is required',
    DescriptionIsRequired: 'Description is required',
  },
  //permission
  userPermission: {
    ApplicationName: 'Application Name',
    isActionCategoryMissing: 'isActionCategoryMissing',
    Controller: 'Controller',
    ActionCategory: 'Action Category',
    SaveAll: 'Save All',
    IsGlobal: 'isGlobal',
    Id: 'ID',
    Name: 'Name',
    PermissionController: 'Controller',
    Action: 'Action',
    Method: 'Method',
    Application: 'Application',
    PermissionList: 'Permission List',
    Placeholder: 'Search Permission ...',
  },

  //UMS
  UMS: 'UMS',
  UserManagement: 'User Management',
  Permission: 'Permission',
  Role: 'Role',
  createUser: 'Create User',
  Userlist: 'User List',
  UserLogList: 'User Log List',
  changePassword: 'Change Password',

  HRDashboard: {
    HRDashboard: 'HR Dashboard',
    TotalEmployeesHasUser: 'Total Employees who have User accounts',
    TotalActiveEmployeeByDepartments: 'Total Active Employees by Departments',
  },

  Dashboard: {
    MISWorkDashboard: 'MIS Work Dashboard',
    ReceptionDashboard: 'Reception Dashboard',
    ArchiveDashboard: 'Archive Dashboard',
    InternalDocumentDashboard: 'Internal Document Dashboard',
    ExternalDocumentDashboard: 'External Document Dashboard',
    UserPerformanceDashboard: 'User Performance Dashboard',
    ITSMSDashboard: 'IT-SMS Dashboard',
    UserSummaryDashboard: 'User Summary Dashboard',
  },
  Npfx: {
    NpfxWellcome: 'WELCOME TO NPFX COMPANY MANAGEMENT SYSTEM',
    NPFXMarket: 'NPFX Market',
    TodayTotalExpense: 'Today Total Expense',
    CurrentBalance: 'Current Balance',
    TodayTotalLoss: 'Today Total Loss',
    TodayTotalProfit: 'Today Total Profit',
    TodayTotalTrade: 'Today Total Trade',
    TradeChart: 'Trade Chart',
    Profit: 'Profit',
    Loss: 'Loss',
    Day: 'Day',
    Week: 'Week',
    Month: 'Month',
    Year: 'Year',
    Period: 'Period',
    DataPoints: 'Data Points',
    Categories: 'Categories',
    TotalAmount: 'Total Amount',
    ExpensesByCategories: 'Expenses By Categories',
    MemberBalance: 'Member Balance',
  },
  ProcessName: {
    All: 'All',
    Completed: 'Completed',
    InProcess: 'In Process',
    Pending: 'Pending',
    NotCompleted: 'Not Completed',
    Approve: 'Approve',
    Reject: 'Reject',
    DocumentProcessEnd: 'Document Process End',
    ReplytoReceivedDocument: 'Reply to Received Document',
    ExecuteandMessageSending: 'Execute and Message Sending',
    ProcessStart: 'Process Start',
    Sent: 'Sent',
    Received: 'Received',
    DueGiven: 'Due Given',
    DueTaken: 'Due Taken',
  },

  //Province
  Province: {
    Title: 'Province: Provices List',
    ProvinceList: 'Provinces List',
    Province: 'Province',
    Code: 'Province Code',
    Placeholder: 'Search Province ...',
    AddTitle: 'Province: Create New Province',
    CreateNewProvince: 'Create a New Province',
    UpdateProvince: 'Update Province',
  },

  //District
  District: {
    Title: 'District: District List',
    DistrictList: 'District List',
    District: 'District',
    Code: 'District Code',
    Placeholder: 'Search districts ...',
    AddTitle: 'District: Create New District',
    CreateNewDistrict: 'Create a New District',
    UpdateDistrict: 'Update District',
    EnglishName: 'Name in English',
    PashtoName: 'Name in Pashto',
    DariName: 'Name in Dari',
    UpdateTitle: 'District: Update District',
  },
  //Education Title
  EducationTitle: {
    Title: 'Education Title: List',
    AddTitle: 'Education Title: Create',
    UpdateTitle: 'Education Title: Update',
    educationTitleList: 'Education Title List',
    educationTitle: 'Education Title',
    dashboard: 'Dashboard',
    iD: 'ID',
    engName: 'Name in English',
    dariName: 'Name in Dari',
    pashtoName: 'Name in Pashto',
    code: 'Code',
    action: 'Action',
    remarks: 'Please Enter Remarks',
    searchPlaceHolder: 'Search Education Title by English Name, Pashto Name, Dari Name or Code...',
    updateEducationTitle: 'Update Education Title',
    newEducationTitle: 'New Education Title',
    New: 'Creation of Education Title',
    Update: 'Updating Education Title',
  },
  EducationLevel: {
    Title: 'Education Level: List',
    AddTitle: 'Education Level: Create',
    UpdateTitle: 'Education Level: Update',
    educationLevelList: 'Education Level List',
    educationLevel: 'Education Level',
    dashboard: 'Dashboard',
    iD: 'ID',
    engName: 'Name in English',
    dariName: 'Name in Dari',
    pashtoName: 'Name in Pashto',
    code: 'Code',
    action: 'Action',
    remarks: 'Please add remarks',
    searchPlaceHolder: 'Search Education Level by English Name, Pashto Name, Dari Name or Code',
    updateEducationLevel: 'Update Educarion Level',
    newEducationLevel: 'New Education Level',
    New: 'Creation of Education Level',
    Update: 'Updating Education Level',
    Country: 'Country',
  },

  //EducationLevelDetails
  EducationLevelDetails: {
    Title: 'Education Level Details: List',
    CreateTitle: 'Education Level Details: Create',
    UpdateTitle: 'Education Level Details: Update',
    EducationLevelDetailsList: 'Education Level Details List',
    Create: 'Create New Education Level Details',
    Edit: 'Edit Education Level Details',
    EmployeeName: 'Employee Name',
    UniversityName: 'Educational Authority',
    New: 'Creation of Education Level Details',
    Update: 'Updating of Education Level Details',
    Placeholder: 'Search Education Level Details...',
    EnglishUniName: 'Educational Authority Name in English',
    PashtoUniName: 'Educational Authority Name in Pashto',
    DariUniName: 'Educational Authority Name in Dari',
  },
  //ContractType
  ContractType: {
    Title: 'Contract Type: List',
    ContractTypeList: 'Contract Type List',
    Placeholder: 'Search Contract Type by English Name, Pashto Name, Dari Name or Code...',
    AddTitle: 'Contract Type: Create',
    CreateContractType: 'Create a New Contract Type',
    UpdateTitle: 'Contract Type: Update',
    EditContractType: 'Edit Contract Type',
    New: 'Creation of Contract Type',
    Update: 'Updating Contract Type',
    ContractType: 'Contract Type',
    ContractStatus: 'Contract Status',
    Current: 'Current',
    Ended: 'Ended',
    EmployeeIsPermanent: 'Employee Is Permanent',
  },

  //Grade
  Grade: {
    Title: 'Grade: Grade List',
    GradeList: 'Grade List',
    Placeholder: 'Search Grades by English Name, Pashto Name, Dari Name or Code...',
    AddTitle: 'Grade: Create Grade',
    CreateGrade: 'Create a New Grade',
    UpdateTitle: 'Grade: Update Grade',
    EditGrade: 'Edit Grade',
    New: 'Creation of Grade',
    Update: 'Updating of Grade',
    Grade: 'Grade',
  },

  //GradeStep
  GradeStep: {
    Title: 'Grade Step: Grade Step List',
    GradeStepList: 'Grade Step List',
    Placeholder: 'Search Grade Step by English Name, Pashto Name, Dari Name or Code...',
    AddTitle: 'Grade Step: Create Grade Step',
    CreateGradeStep: 'Create a New Grade Step',
    UpdateTitle: 'Grade Step: Update Grade Step',
    EditGradeStep: 'Edit Grade Step',
    New: 'Creation of Grade Step',
    Update: 'Updating of Grade Step',
    GradeStep: 'Grad Step',
  },

  //PositionTitle
  PositionTitle: {
    Title: 'Position Title: Position Title List',
    PositionTitleList: 'Position Title List',
    Placeholder: 'Search Position Title by English Name, Pashto Name, Dari Name or Code...',
    AddTitle: 'Position Title: Create Position Title',
    CreatePositionTitle: 'Create a New Position Title',
    UpdateTitle: 'Position Title: Update Position Title',
    EditPositionTitle: 'Edit Position Title',
    New: 'Creation of Position Title',
    Update: 'Updating of Position Title',
    PositionTitle: 'Position Title',
  },

  //JobPosition
  JobPosition: {
    Title: 'Job Position: Job Position List',
    JobPositionList: 'Job Position List',
    Placeholder: 'Search Job Position by English Name, Pashto Name, Dari Name or Code...',
    AddTitle: 'Job Position: Create Job Position',
    CreateJobPosition: 'Create a New Job Position',
    UpdateTitle: 'Job Position: Update Job Position',
    EditJobPosition: 'Edit Job Position',
    New: 'Creation of Job Position',
    Update: 'Updating of Job Position',
    JobPosition: 'Job Position',
    department: 'Directorate',
  },

  processStatus: 'Process Status',
  application: 'Application',
  representator: 'Representator',
  list: 'List',
  create: 'Create',
  year: 'Year',
  ums: 'User management system',
  group: 'Group',
  hr: 'HR',
  emp: 'Employee',
  employeeDegree: 'Employee Degree',
  employeePosition: 'Employee Position',
  province: 'Provice',
  district: 'District',
  //HR
  HRLookupTables: 'HR System',
  contractType: 'Contract Type',

  educationTitle: 'Education Title',
  educationLevel: 'Education Level',

  grade: 'Grade',
  gradeStep: 'Grade Step',
  positionTitle: 'Position Title',
  jobPosition: 'Job Position',
  employeeHealthStatus: ' Health Status',
  cardPrint: 'Card Print',
  //CommonLookUps
  CommonLookUps: 'Common',
  //News
  news: 'News',
  newsList: 'News List',
  addNews: 'Add News',
  //Training Videos
  trainingVideo: 'Training Videos',
  addTrainingVideo: 'Add Video',
  trainingVideolist: 'Videos List',
  trainingVideoPlayer: 'Videos Player',
  //Book Diary
  bookDiary: 'Book Diary',
  contact: 'Contact',
  UserReport: 'Users Report',
  EmployeeReport: 'Employees Report',

  //Welcome
  Welcome: {
    Title: 'Welcome to Afghanistan Water and Energy Enterprise System (AWEES)',
    Text: 'Afghanistan Ministry for Energy and Water (MEW) is responsible for all policies, regulations, development projects and Coordination for water and energy sector in the Islamic Emirate of Afghanistan.',
  },
  Report: {
    FromDate: 'From Date',
    ToDate: 'To Date',
    Dashboard: 'Dashboard',
    ExpenseReport: 'Expense Report',
    ExpenseTransaction: 'Expense Transaction',
    LoanReport: 'Loan Report',
    LoanTransaction: 'Loan Transaction',
    TradeReport: 'Trade Report',
    TradeTransaction: 'Trade Transaction',
    Filter: 'Filter',
    Print: 'Print',
    Reset: 'Reset',
    GrandTotal: 'Grand Total',
    No: 'No',
    Branch: 'Branch',
    LoanType: 'Loan Type',
    CurrencyType: 'Currency Type',
    Amount: 'Amount',
    Paid: 'Paid',
    Remain: 'Remain',
    Taken: 'Taken',
    Given: 'Given',
    MainAssetCode: 'Main Asset Code',
    UserName: 'User Name',
    Date: 'Date',
    Description: 'Description',
    ExpenseType: 'Expense Type',
    Dollor: 'Dollor',
    Afghani: 'Afghani',
    ProfitAmount: 'Profit Amount',
    LossAmount: 'Loss Amount',
    debitAmount: 'Debit Amount',
    creditAmount: 'Credit Amount',
    balanceAmount: 'Balance Amount',
    transactionDate: 'Transaction Date',

    Partner: 'Partner',
    partnerPhone: 'Partner Phone',
    loanAmount: 'loan Amount',
    paidAmount: 'paid Amount',
    remainAmount: 'remain Amount',
    expenseType: 'expense Type',
    tradeAmount: 'trade Amount',
    profitAmount: 'profit Amount',
    lossAmount: 'loss Amount',
  },
  AssetDetails: {
    MainAssetReport: 'MainAsset Report',
    AccountDetails: 'Account Details',
    TotalCreditAmount: 'Total Credit Amount',
    TotalDebitAmount: 'Total Debit Amount',
    CurrentBalance: 'Current Balance',
    AssetTracking: 'Asset Tracking',
    LoanTracking: 'Loan Tracking',
    ExpenseTracking: 'Expense Tracking',
    TradeTracking: 'Trade Tracking',
    WidthrawalTracking: 'Widthrawal Tracking',
    MembersBalance: 'Members Balance',
  },

  app: `app`,
  user: `user`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  report: 'Report',
  NoRecordFound: 'No Record Found',
  categorys: 'Categorys',
  propertyConditions: 'property Conditions',
  PropertyMenu: 'Property Management',
};

export default en;
